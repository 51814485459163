import { ProvinceService } from "@/config/api.service";
import { FETCH_PROVINCE } from "./actions.type";

function prepareLista(data) {
  const lista = [];
  if (data.length == 0) return lista;
  data.forEach(element => {
    lista.push({
      text: element.name,
      value: element.key
    });
  });
  return lista;
}

export const actions = {
  async [FETCH_PROVINCE]() {
    const { data } = await ProvinceService.listaProvincePiemonte();
    return prepareLista(data);
  }
};

export default {
  actions
};
