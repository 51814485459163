<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="68.895"
    height="66.013"
    viewBox="0 0 68.895 66.013"
  >
    <g
      id="Raggruppa_14052"
      data-name="Raggruppa 14052"
      transform="translate(-649.104 -8398.303)"
    >
      <g
        id="Componente_51"
        data-name="Componente 51"
        transform="translate(649.104 8398.303)"
      >
        <g
          id="Raggruppa_392"
          data-name="Raggruppa 392"
          transform="translate(-29.654 -324.425)"
        >
          <circle
            id="Ellisse_39"
            data-name="Ellisse 39"
            cx="6.365"
            cy="6.365"
            r="6.365"
            transform="translate(60.095 377.707)"
            fill="#07b6d7"
          />
          <circle
            id="Ellisse_40"
            data-name="Ellisse 40"
            cx="6.365"
            cy="6.365"
            r="6.365"
            transform="translate(58.316 353.437) rotate(-76.718)"
            fill="#07b6d7"
          />
          <path
            id="Tracciato_788"
            data-name="Tracciato 788"
            d="M29.654,339.915a5.055,5.055,0,1,1,5.055,5.055A5.055,5.055,0,0,1,29.654,339.915Z"
            transform="translate(0 -1.673)"
            fill="#07b6d7"
          />
          <path
            id="Tracciato_789"
            data-name="Tracciato 789"
            d="M29.654,385.7a5.055,5.055,0,1,1,5.055,5.055A5.054,5.054,0,0,1,29.654,385.7Z"
            transform="translate(0 -9.014)"
            fill="#07b6d7"
          />
          <circle
            id="Ellisse_41"
            data-name="Ellisse 41"
            cx="5.055"
            cy="5.055"
            r="5.055"
            transform="translate(88.439 332.351)"
            fill="#07b6d7"
          />
          <path
            id="Tracciato_790"
            data-name="Tracciato 790"
            d="M99.665,382.707a5.055,5.055,0,1,1,5.055,5.055A5.054,5.054,0,0,1,99.665,382.707Z"
            transform="translate(-11.226 -8.535)"
            fill="#07b6d7"
          />
          <path
            id="Tracciato_791"
            data-name="Tracciato 791"
            d="M63.545,328.544a4.118,4.118,0,1,1,4.118,4.118A4.119,4.119,0,0,1,63.545,328.544Z"
            transform="translate(-5.434)"
            fill="#07b6d7"
          />
          <path
            id="Tracciato_792"
            data-name="Tracciato 792"
            d="M63.145,375.939a4.118,4.118,0,1,1,4.118,4.118A4.119,4.119,0,0,1,63.145,375.939Z"
            transform="translate(-5.37 -7.6)"
            fill="#07b6d7"
          />
          <rect
            id="Rettangolo_1529"
            data-name="Rettangolo 1529"
            width="1.528"
            height="42.047"
            transform="translate(65.209 342.463) rotate(-0.511)"
            fill="#07b6d7"
          />
          <rect
            id="Rettangolo_1530"
            data-name="Rettangolo 1530"
            width="1.679"
            height="36.353"
            transform="translate(34.056 339.249)"
            fill="#07b6d7"
          />
          <rect
            id="Rettangolo_1531"
            data-name="Rettangolo 1531"
            width="1.679"
            height="36.608"
            transform="translate(92.845 338.997)"
            fill="#07b6d7"
          />
          <rect
            id="Rettangolo_1532"
            data-name="Rettangolo 1532"
            width="1.68"
            height="26.073"
            transform="translate(37.685 377.497) rotate(-75.78)"
            fill="#07b6d7"
          />
          <rect
            id="Rettangolo_1533"
            data-name="Rettangolo 1533"
            width="21.561"
            height="1.679"
            transform="translate(70.924 382.301) rotate(-20.319)"
            fill="#07b6d7"
          />
          <rect
            id="Rettangolo_1534"
            data-name="Rettangolo 1534"
            width="1.679"
            height="26.286"
            transform="matrix(0.25, -0.968, 0.968, 0.25, 37.655, 340.541)"
            fill="#07b6d7"
          />
          <rect
            id="Rettangolo_1535"
            data-name="Rettangolo 1535"
            width="24.474"
            height="1.68"
            transform="matrix(0.952, -0.306, 0.306, 0.952, 68.863, 344.946)"
            fill="#07b6d7"
          />
          <rect
            id="Rettangolo_1536"
            data-name="Rettangolo 1536"
            width="23.795"
            height="1.68"
            transform="matrix(0.964, -0.265, 0.265, 0.964, 37.616, 334.345)"
            fill="#07b6d7"
          />
          <rect
            id="Rettangolo_1537"
            data-name="Rettangolo 1537"
            width="1.679"
            height="28.215"
            transform="translate(64.901 330.106) rotate(-75.418)"
            fill="#07b6d7"
          />
          <rect
            id="Rettangolo_1538"
            data-name="Rettangolo 1538"
            width="1.679"
            height="37.95"
            transform="translate(61.39 330.389)"
            fill="#07b6d7"
          />
          <rect
            id="Rettangolo_1539"
            data-name="Rettangolo 1539"
            width="25.633"
            height="1.679"
            transform="translate(37.629 375.889) rotate(-18.302)"
            fill="#07b6d7"
          />
          <rect
            id="Rettangolo_1540"
            data-name="Rettangolo 1540"
            width="1.679"
            height="27.641"
            transform="matrix(0.213, -0.977, 0.977, 0.213, 63.134, 369.159)"
            fill="#07b6d7"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "PMIcon"
};
</script>
