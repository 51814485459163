<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="62px"
    height="62px"
    viewBox="0 0 62 62"
    enable-background="new 0 0 62 62"
    xml:space="preserve"
  >
    <image
      width="62"
      height="62"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA+CAMAAABEH1h2AAABfGlDQ1BpY2MAACiRfZE9SMNAHMVf
U6VFKg5mEO2QoTpZEBVxlCoWwUJpK7TqYHL9hCYNSYqLo+BacPBjserg4qyrg6sgCH6AODk6KbpI
if9LCi1iPDjux7t7j7t3gNCsMtXsmQBUzTJS8ZiUza1KgVcEICKIMEZkZuqJ9GIGnuPrHj6+3kV5
lve5P0d/vmAywCcRzzHdsIg3iGc2LZ3zPrHIynKe+Jx43KALEj9yXXH5jXPJYYFnikYmNU8sEkul
Lla6mJUNlXiaOJJXNcoXsi7nOW9xVqt11r4nf2GooK2kuU4zjDiWkEASEhTUUUEVFqK0aqSYSNF+
zMM/7PiT5FLIVQEjxwJqUCE7fvA/+N2tWZyadJNCMaD3xbY/RoHALtBq2Pb3sW23TgD/M3Cldfy1
JjD7SXqjo0WOgIFt4OK6oyl7wOUOMPSky4bsSH6aQrEIvJ/RN+WAwVugb83trb2P0wcgQ10t3wAH
h8BYibLXPd4d7O7t3zPt/n4AaypypEnnTzsAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADq
YAAAOpgAABdwnLpRPAAAAVxQTFRFAAAAcL/Hbr/Dbb/Cbb/Dbb/Dbr/DcL/Fbb/Cbr/Cbr/Ebb/E
cL+/br/Cbr/Dbr/Dbb7DbL/Dbr/Dbr/Cbb7Dbr/CbL/Cbb3Cbb/Bbb7Cbb7Dar/KbMHBbb7Cbb/C
bb/DgP//gNXVbr7Dbb7CbL7CbLrEbr/DbL/Ebb/DbL/Cbb/CbL7Dbb7Cbr7Db77Dbr/Fbb/Dbr7D
br7Cbb7CbcDCbb/CacPDbb/Cbb/CbMTEb77Ebb/Dbr/Cbr7Cbr7Cbr7Ebb/DbcDCbb/Dbb/CbcDD
br/Dbr3Fbr/DbsDDbb/Dbb/CZ8HBbb7Dbb7Ebr/Dbr/Dbr/DcMLCar/Gbb/Ebb/Cbb/Dbr7Dbb/C
ZszMbr7DgL+/br7Dbb/Ebb/Dbb3FZszMbbbbbcHGbr7Da77Eb8TEbr7Cbb/Dbb7Dbb7Cbb/Cc7+/
br/Dbr/Cbb/Dbr7Dbb7Dbb/Cbb7CbL/C////QD2P3wAAAGt0Uk5TACBvj7+vfzCf319wEKDPkMBA
gO/QsFD1y/DxGCHv+rsCBsjrzRrTeIjEYMv8+TcsRP30+L3eEeeoGlqDrLWGSrzK+t9N5m3a2v7K
JfvgSMP2GSRstJjouArYBOQ4x0YFBzGiKx52wvXNpBRLskdrAAAAAWJLR0RzQQk9zgAAAAlwSFlz
AAAuIwAALiMBeKU/dgAAAAd0SU1FB+QHFAsjIdLPZzIAABXBelRYdFJhdyBwcm9maWxlIHR5cGUg
ZXhpZgAAaIHtmldyJDmWRf+xilkCtFgOpNnsoJc/58KDspis6rb5bDKTodwdwBNXwMPsf/3vMf/D
Tyotm5hKzS1ny09ssfnOk2qfn+fR2Xj/3p+wX5+5r++b9w88bwUd+bzMr/dd5/30cUKJr/fH1/dN
ma/r1NeF3PuFnxloZD1fr0m+LhT88757vTbtdULPn5bz+u/n67Kvi39/HQvBWInrBW/8Di7Y5+8z
Unj+d/4X/vKcA919J4T0vP/X+Jn30P0QwPdn3+Jn32YWPsLxXOhtWflbnF7vu/Tt/bcLKkqfZ+T8
+8j+84zqcMl+/vkUv3NWPWc/q+uROmoxvxb1tpT7jAMH4Qz3tMxv4X/iebm/jd9qu51kbbHUYezg
RXOeaB4X3XLdHbfv43STKUa/feHR++nDfa+G4pufQSmI+nXHFxNaWKGSm0nmAm/797m4O27TeAxW
GXk5jvSOiznO+PJrvr/xn/5+udA5KnPnbH2PFfPyKlmmoczpL0eREHdeMU03vs48D/b7jxIbyGC6
Ya4ssNvxXGIk91Fb4eY52GQ4NNqnX1xZrwsQIsZOTMYFMmCzC8llZ4v3xTniWMlPZ+Y+RD/IgEsm
+cUsfQwhk5zqNTbnFHeP9ck/bwMvJCKFTNtUEtRJVowpZvqtUkLdpJBiSimnkmpqqeeQY04555KF
U72EEksquZRSSyu9hhprqrmWWmurvfkWgLFkWm6l1dZa7wzaY+daneM7bww/wogjjTzKqKONPimf
GWeaeZZZZ5t9+RUWEGBWXmXV1VbfblNKO+608y677rb7odZOOPGkk0859bTT37P2yurXrH3P3O9Z
c6+s+ZsoHVc+ssbbpbxdwglOknJGxnx0ZLwoAxS0V85sdTF6ZU45s80HA1Z5ZpmUnOWUMTIYt/Pp
uPfcfWTuj3kzRPffzZv/KXNGqfv/yJxR6j5l7q95+yFrq1+4DTdB6kJiCkIG2o8Ddu2+dvFS96Mc
kGK7do4IqI/EGmfZq5ycjt+WPjgMUXaxswdXZzVn8xJS8WMM73PyyaU5Tw664imMQiQYbNNjK/U0
9tkrnAFNN+sqoWtuBNdMTqHNvcNZ66w8Ruwr0aCLILlIPbq4lyMPZ3HG8ZNxTgqLz8Dsxlur33PN
LCD42xGxldpCZNqkJUQ72vJMwJ1ciFQuzD5nAHQk4DYS6RNnScRxHuN8mSE8sSjnnps0c52/Y553
pm3kvNecrvR9Qt2j7QbI9LjyzCcO16DsXU/MBQ5P/KN4KKb73NrfHvMzdHhNwjaTssqgstSz59hp
2pzXoQDOJGsxE0fisM/yradScoyTQvOjtT3hJ5cI/XbjmMryyczJ4+TVe9ET4jfr0khjnBHyXGuA
XSetXdwqQSneT1LIa+00wzbB0Qmdxk67ljmYdVnxROLEp/TRyRBqKeTvQI6vA8LobVFkmZoNyDWO
NHTPya2KE0+dey1mnhn3dPKdNlO0tywHaWpj3jIaJ4Q9lw1zDZ6MyoxNim1mjbh8z7zo9EpnDPD+
1EE0V78lP+ueVk9QZPfxLt1OCiUkSNow0T42wU9D68ik+lDY2bW1GCJY5jR6bqR60+r0OFCxE/Li
zNC2m2MeF3anjoAOPkZ+eR/BDw+A+EfgDCZ/5vSDIEsrUHcxkSRKPc8+dxgrjhwr42zjKp3kndTn
aXA+V6Rgqe6KDE1U2ejM2ffFcLMUVj0bXUcB6ZwIC1OxIVtmZJtrO4+0WYGzOwVgJm6fFmCWxlOG
CW3Sn5qV7qKvV19MNNEtYy3o1MRjxxYGIrBpYECUei91M4mkwMLKlIIF+xyKMurcxVJcz9RBQJ5u
JthONrxedadIkXhXUJ7FTfDDbiD71AKssYhOHwLFAxrwsR7QPjqwtrnKy70taGOo/NMhcEpi+/gk
c6W910l9FKqhUfBzz3haHT4wEDkYo6FbOrAf9iBZILgBaN0aoCCYFHZCdhExCELhKMHt8WMPA5e1
MFewHj23oo8GcIdnbN0x1kpVzwkJTritwIyNaaTAIP6pn1VVOaw7bYDrJAiNMugRVWKQj1BeQ/Vd
BVzdol3KzrT1CcS/uly634STjoSl6J4N4Hq6kTwxqJMqDQUvsiql42mwhnL5m7L7Q9UBkoZTgHff
tvSR87vDAavenuqLNXz0VwYJI4EByXnWqUswIFE4TnrVHImzNVPY4qJpwZDpU49pPghx1GYZlIdb
aBk+ILi8LDm0hJCYNYHdbho6bMwwZixPA9qZO1cGQSbzRh3M6JEkkgRZYmFUoj17HhuBQm8c2GI7
uwx1U8Yo0M+iT7KoOQzaOXsGYqSN/oYGLdJjWUAqJ0RK0YoySmMMQtQySGiIMTo8XlqEI0gWOYN6
bWVmvATH7BkFvlisqYORS3WGB8hLQENTlFMoSBztBdeEfgICKVkH8fGC3/HQ1IthSnTE/k/UYpjp
Yhk7LukdsN5X0Hy2AtuCT4Ryyzp2qpSJEQfqybrA+tsJyIp6nvoGjyjwQH0v6rv5iOSJmB4/KRA6
FBEXqG9WxpTgG8cf8SYMii6BuA81Qulb4wfdoa4sA6JEzTC19vQXYPkJg/ZYxy6plF6FKZQFEQGh
+Qf2GubG2gLwCiq1W10p2l0DimJWZRlliEAAW0pnLaswU2ZDha7MCoh3gpSGqayCCu3jFVBS8meu
/iWe5m8DOii5cRdEkgssdvJmVT2eEWHyt1owC1aj2MXvLlV3KdFBmjs/xJgvf9Eo8CWF4ubCn4Bw
CwqDyiNqk3lObyDx0ilOf58sCBrgPsHB+UiiZV/tK2Lxn8ThXx4NT8gppDo0sg85SW0WQouqLD4h
eNI6ZaY7M0BfQnIPulyvY2iknTejadiCiUq4ma2vvAM3H3m/Oi6jIqMk39BVskw4wQfMxutzcw+I
qDuu/+mQvKjFfLEWRYfvy3cdlAVg8lkCvD2aT2+EidiSswWXJhpCskOXX0EqEXmErJhkAON59clV
bcodIoVgYxLu6vKxlB0di56CnihE1jGRJ3AU87iXnEPKK0oRlyl5CTlfDCjLSHxj6O9qiac0ofNZ
YPXMJjKxeIWbRH2SnAK+CtcoyPXNWsXKLI2iCIB1iyhVAGv0sPQ+Gc9QCZOONSPzlLiAxxJSZwtv
XWaHsDa4lTqyJhDfn4L39sgCwMIMbh7KEj2aIJDhMU6AJdCb8STIbi81sqmkRarFhKBAC+e9amCU
v1QN3Qqpg4QkFkzAOgED22zi2Liyc/DfTIli8nEj1AiQBHlZtAXqjdrGL919l+5p/jegqa+CM0Ka
MzaG9ca4S6d2bA54QzQzMpqJY4xkjhKyE0GUUjziFP6wGrpAXsVcSbmJMpo34mEsHLcUCwxE9ERl
3almQNEhPqpmXDqiqWDrMArwKqOOaBidRqW3PHI+jVYzQOchUeUYSJuImZjh3oD1yBErvEt3KECs
JMO+N675oZNJUELqN46cFENDBKL0Z8NS41pj1fbSXhQBDopSLQI9b+QvwtxPb3x0xltfyKcW1feA
FbCwb+V5q5sqo7ox4eQCqOX5rW589gWuozJgtR1pRCBar682JYn1dO19JRwEuIo0JPn8K2ObgVuF
n4erg3hIGaQmgdJRIkSEhG8s7h47v9JS637TlQQ5JzevrjTO1UfQS1g6tCLCUm4WIkbuwG6knOpj
OKlK/IzW27L1m/LGP7aCewEuDMYWdYA8wkmgs3FOtDUKRXsRLu/kVnKUFqDmPRYFyRjB5UJ38Mfi
UIBRh8gz0dkyANbrhr8QEMTBk07QHM0BwIODVGNFcDZUK/ovoJM8Y/g5t5np7nXSbE2TigwNUwYX
O2OSyg5E0ScdfYgWQc3I2R6M1MHWBj7tFT81l2nI2CGdpa0YSD0VyUmkmzgXqM29w8ja5aTfB6gN
UEdSfGbBiU0knaVa+zBA8JRXD7SOCw0P6qqUfNRmRt8BFqciqzoWJZTQ98x8PvXbwofENH1Tv2h0
mxFFlGKmtbTHEpBvFnW1NUmLIZd+b2li5FpUnLAJZCHNTrnUtQ2CwzfwC69daAvSijpHSCIx3QqB
PwObDc8uj+x2SOhjO0YmLwKRA02KG08Z6Ud2GIJMMcxBUXaYtlDpcUfhALUd8MBqNUJAlWjvBqEJ
B44g/+8kpdI0O2r7jKGIAikSWI1ZkNGIJlE5wFHnojGxKoitN9r89JgIZ0AfLc8QjbQX6e+2QHsa
GWXcS8KslIhUaRtpiSiuA4Ph0d6e1vWTo6I6ew90NtaAsxdHexQ0yunIhuDkA4V4tTat0FhwlCdd
xHWlpk2wFDNUCTDJ4AMj2HNKBlihFxGQsM7j74gJDZZkZ4pMH2gIPKB/6G3M5cB1QDNk1onmcUcD
3QPGE5peYiw9ETh/SNVLT9BG7bt0I5NU1pR+IyzyP30aAeyiOQLIRTcC026wQNVYFpVCRfFyvAV7
L+fDuOQOADy01RF1oXwjOhvDg04HjgKlRD23R6NNT0QQxLtou+mccbAz+gQOuFrudWXxH1c2CNXX
pQGV5+LaYGkqzEORewgf54S0RQX1DijtBXOvvcWmCQvewL09TKrEnIZ0DZAk3fgbXK2226B0VlGg
ZCKtfbFANXN5JCL4CQ5PNKSP8i/UuAkZSxjF2QvklSRJd2MA2ZhEOHdfoGj7IdS7L8CvvwpkdN8m
XQWQMVmjzdCGTVoNL5PR3oy1ZfZ4ejFry2WRKlqAASO6cBHF1IAW5xpmFd9PiM1kwtL2VKMN8Dy2
7NwudYAmdAsZgsqrReo2Y/tq69oBpqhtxnzKeYQUwzTP1k2Qc2zntXMTpIJwiXfnxtK9VTs3nDSZ
/qVj8BzCXrLN5xnQfBnR/2FE+UFcpx0AVkUgRtCq5wy/7yw5U50zCyzB0w4ZBg+8PWRJ03SG6phl
suOlTxCqXnop9+3zaMyLdfiD4apMA6GlLZaJOqDceoO6WptihyGoRJpJqz7bjRsKXLckN+3Zb+XR
6pQgoryYo3tU/dYy+loS+EwwBNUmZYu8DJFOpfRKUe1CgYXil73+eqb5cmp99jtzeEofaJXHub62
0R8AFZYmvq7rtH0I9dP+Bw1ZQP6McppUb7/U49emGBtGDPet2w71enjSuoi+ZRjYY7W7aQP5RW0I
umbw2wGR7cWEvQl9QsHLgDhUnYRGcOiaT/ICTexRGJJ+gH4fNOdAJhiqp8sR51OenaeNas4y0Hfj
ybfoxiL82ngihG5TRwdnQDOx3LoQRSgFrCgnk/iSRwWyNwKR5WAR5a96J9ZvnUg56Oaih2ePnCgQ
s7c4SXBGI5uJ1EcTzCWGZ+U2otv6AXvRzKKwGrYPMoVFeUJZwIcFE7LU4meQNhRQaEbm5CRknZoT
5GOlut+eyFxmQFY3hi342clnhH/dfL6hnpTXg3sGoHug7wV8XwG1fAPUBCxRAZDOeke9sXW8Ibea
nkY5JO3WK/1wcRoQo0HvPuLVe/FoH7EQVd3km7U5WyvVEREehlLcO+KDqdKJsGhUCSIAmRHi1m5k
fFgVwvx1D998eUMdNDHZ8uuzBtqV6sWcto2VxcMVzNrx4kmhQHoEdBGGDqO1UA3MfRFe/J02iZA6
aG18xbl3H+qE/UCEQ6Us39EvW61Ho8XFunReVK9pk8sPERBIMXRPhJKbUlEH/7HAOSKFTE6noZKz
Ckw7UDhXbcwA7pB/NKKVSr0kVxCx/pCb9Tjq7P/sCOezWdCGwCUtn7UTAehol33OBvINkQzjdxID
6MvAamMNG4njp70phEuOgdbKuuuxjt8H9WWgN7xShd0cUrrkiphf2q+mJB1o2JDgrFgWkNG9VWeg
d2SWF5nRn0F/D3OIZrIKZhCjLz+87hZci0djgByJ9Yy3Zbo/1YD59cbO22NJ6VjNLFLYQt3VH8il
BdE+qKZmRJaYQO2O40ide/aunjZJCshFSoQxgKFLIv/gLNKDWaK+MBmPWMb45V+2sDL9ipy/d5pa
oiYRTOe5qwJiQCfvt5kQEZ/uM6EriDrZ7NQ3WUxHHoTyud7v2TXxiCnQLLd7N+hxgOEUA6Pd6sVn
MrcdEPt3H1Vhcc+2hgLzNPHSVhdDAaP52S1EoaF5aE/ja0wY1qcz9QWK37atfnk0qBPwsLhnPwuF
9dpZA6G08VSkDPExdLK0xbjaLd/tIJBig8bALx5qmbtD2img89pqmmH+vEvywyOHDoD4AtvUzb47
izX0TQ8XZVQQXZ0Aw4eEnYqGTCMUcoZVpBVc+TX62B+WgGB3puyTVdP1YjWACpXWBUOhciM1rlvd
2nh8bYbYEn+ubfM3Nf3g3U+Ffcta2csSAdARORPj4HUwREL2ArfRhdQ7xOCgK19RK1XchjkAx+ES
l+BnCFKWUAcg2KtuE9XmsX8JzTXl5YPuGVOCGLlr5W3ALsjKHyf7sZiJBu2iIkScrj3Nqw6i+8FA
vRspwkpudE/h0AjxMDUEM6qRcO50v+RiekXpRYdPRcXCUjFgeidtHDcJ1W0pJo81VaG1ki/jQrKb
Ik4PzyIU2phGN1Yz6A+YV4yHlLoEfj8Ab2dcFCA2Hx1JZVIZ2M0BAyNZaTvtfHTOw9VBR8iLOtIs
Hjf1bH+VluWxdn1ntH8AWubXAxBsDdY6d19zt9Hj0I4ZP8AQnc5CiDmadOFp1fm1k5eg5MSCZCUl
wM2Sokbaoy5eG1wtRO2taIPLao9h3w2uwOknmX1sOMTz7l065DNDQFeYnq492OrKfLbFPBWN+Ydj
FTRa2UWO1E2wIotmUC0d24DLiLcTqaJ5/tysBwat3YlWPNYbd5SfDULz2iE8t+e02LxW74zVdGOz
eX1bbGBMUpLvXAuEIQ9kcFAcHpf5bGBmo21B++xgNoT4KhO+O2nOoNuL/lbw3al1SNn5sQOY5vte
LQS2vO5B6mamPsHWZUKKUHoWiTRK/xgxzd8eiFBOdy/x9+yZJ336lYHYz8ZaGfHs2dplaUEtnpwG
DeJpB9j2gViAQioMMGBs/pi+HE5mBS4CtSBW0bqPcZozzzXQXIxpYaeAJsQ1QlFB20EVRYAgDehw
zkuGE8+YFbiZElSAZ8CQAscBY6pdWYf+z9pTCjTVyDYH3efTFxwsTASKDID7bOOyvmkA6lV/uaF5
KZ/jQtT3a2aheVkYKHnwl+gn5pEF2zHugjZNZBXhcxoxgmNARXu+n0eg75lSt/mG68uYrxFBLMYc
2SDVV/RXNiztxVKPd/c8HC0+3d1zgnbXzhIVtOEnAPaxIU3J1WHES0tfF4nzKhCUKRE5Eljl+e7I
rixiUf/6fgyVhTBB5iUkLsfVUSjAAkE6vwrqfZ0gQ+mwW6dpXzVzSd3yRFJaiij1is0SvkKwijjK
QV9zemTgSFhRl/MoNEX3ixZLZQDpHgMcrVLot6tQOWaNMotAjBMhDy+rrg0spwokBMm8blvH8E9p
+g+P5j898b8X+jceUUcLRjf/B1dIGWQFlWTKAAACQUlEQVRIx+2U2V8TMRDHUywC7taWStGCWkAF
QRGxigcqh4LWE+X0BAXP2S3V///BJN1kk+xsmvLkQ38P008zv2+ymSRDyP+hTNexbLb7eM+RLJle
iNR3Ig1Ot3igKOtjtMWSA00nEd5iyYOhbIK2WHogIc+gbZZCMtdn4BZLBhD160W3WDwsl0uvumEp
YrlTGm6zoDlojUPKmTAN2I9NsRSwXKFV4aVFqUsgg457elLDfTkQhiIY94ZZ6kEQhUjCUor+H7LJ
D5srZPRrU6IgNfAgcGEZFAN1GfRjjyyhDLpFLN+guYCF08bihJxhuwrE1qjKscUfiEvHTUPDBn32
HM2wiSWtthzJ8687D1AZ0ejRMYALzW1FtP4mSLEsd3/x0jjAhJa9DDA+OhnTuUQ/8adKdIbylasw
fW1mmgYld30WbszQUL3JLIUi2szkDbk1x4McGr4NMMUDaa0KwJ1muHtv/v6D3ocLi3LEQXKtpWVR
S3hkfI9NfKeP/4hCs5+VVaMaNtE6P3kqDrjeoOvXa+ZZWMROGZRT5jM9G3HFyfMX8o6Jexi+dNs5
0ysJ/RWvIIC8M/5af1/Nbay9caTf1vjrDngI2QNHOki61uPe0oj7D2w44ptKZ1Ma3JYjjvZVgKoj
Pobj4Lvh2zg9u+OG53H8nePHD+K46715/wHFPzriZAKjP7nSZG4NwfudcfI5SW+709HFU1XZbQcn
Xk2Fl+f32qIJ+dItJ6h+3W8TZjr49n0Ifvz81fX7CHBHHXWE6R98D/tobWrUlwAAACV0RVh0ZGF0
ZTpjcmVhdGUAMjAyMC0wNy0yMFQxMTozNTozMyswMzowMAAeWlgAAAAldEVYdGRhdGU6bW9kaWZ5
ADIwMjAtMDctMjBUMTE6MzU6MzMrMDM6MDBxQ+LkAAAAGnRFWHRleGlmOkJpdHNQZXJTYW1wbGUA
OCwgOCwgOBLtPicAAAAhdEVYdGV4aWY6RGF0ZVRpbWUAMjAyMDowNzoyMCAxMDozNTowN5qkAl4A
AAATdEVYdGV4aWY6SW1hZ2VMZW5ndGgANjIwkjNyAAAAEnRFWHRleGlmOkltYWdlV2lkdGgANjKa
dkUVAAAAGnRFWHRleGlmOlNvZnR3YXJlAEdJTVAgMi4xMC4xMC091QEAAAAbdEVYdGljYzpjb3B5
cmlnaHQAUHVibGljIERvbWFpbraRMVsAAAAidEVYdGljYzpkZXNjcmlwdGlvbgBHSU1QIGJ1aWx0
LWluIHNSR0JMZ0ETAAAAFXRFWHRpY2M6bWFudWZhY3R1cmVyAEdJTVBMnpDKAAAADnRFWHRpY2M6
bW9kZWwAc1JHQltgSUMAAAAASUVORK5CYII="
    />
  </svg>
</template>

<script>
export default {
  name: "CO2Icon"
};
</script>
