export const FETCH_RILEVAMENTI_STAZIONI = "fetchRilevamentiStazioni";
export const FETCH_INDICATORI_STAZIONI = "fetchIndicatoriStazioni";
export const FETCH_STAZIONI = "fetchStazioni";
export const FETCH_PROVINCE = "fetchProvince";
export const FETCH_CATEGORIE_INQUINANTI = "fetchCategorieInquinanti";
export const FETCH_TIPOLOGIE_INQUINANTI = "fetchTipologieInquinanti";
export const FETCH_PARAMETRI = "fetchParametri";
export const FETCH_DETTAGLIO_STAZIONE = "fetchDettaglioStazione";
export const GET_FEATURE_INFO = "getFeatureInfo";
export const GET_FEATURE = "getFeature";
