<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="71px"
    height="71px"
    viewBox="0 0 71 71"
    enable-background="new 0 0 71 71"
    xml:space="preserve"
  >
    <image
      width="71"
      height="71"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEcAAABHCAMAAABibqotAAABfGlDQ1BpY2MAACiRfZE9SMNAHMVf
U6VFKg5mEO2QoTpZEBVxlCoWwUJpK7TqYHL9hCYNSYqLo+BacPBjserg4qyrg6sgCH6AODk6KbpI
if9LCi1iPDjux7t7j7t3gNCsMtXsmQBUzTJS8ZiUza1KgVcEICKIMEZkZuqJ9GIGnuPrHj6+3kV5
lve5P0d/vmAywCcRzzHdsIg3iGc2LZ3zPrHIynKe+Jx43KALEj9yXXH5jXPJYYFnikYmNU8sEkul
Lla6mJUNlXiaOJJXNcoXsi7nOW9xVqt11r4nf2GooK2kuU4zjDiWkEASEhTUUUEVFqK0aqSYSNF+
zMM/7PiT5FLIVQEjxwJqUCE7fvA/+N2tWZyadJNCMaD3xbY/RoHALtBq2Pb3sW23TgD/M3Cldfy1
JjD7SXqjo0WOgIFt4OK6oyl7wOUOMPSky4bsSH6aQrEIvJ/RN+WAwVugb83trb2P0wcgQ10t3wAH
h8BYibLXPd4d7O7t3zPt/n4AaypypEnnTzsAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADq
YAAAOpgAABdwnLpRPAAAAPxQTFRFAAAAAEiAAEp+AEl9AEp+AEl+AECAAEl9AEl8AEl+AEp+AEqA
AEp+AEh8AEp+AEp9AEl9AEl9AEl+AEl8AEl9AEh9AEp+AEp9AEl+AEh9AEl+AEp8AEl+AEmAAEp9
AEl9AEp+AEl9AEp9AEl+AEp9AEl9AEp9AEl+AEh+AEl9AEl9AEh+AEltAEl+AEp9AEh8AEl9AEl9
AEp9AEaAAEp9AEZ4AECAAEp+AEqAAEp+AEl+AEl+AEl+AECAAEl9AEp9AEp+AEp9AEeAAE52AEl9
AEp9AEl+AFVVAEl9AEp9AEl9AEp+AEl9AEl+AEp9AEh8AEh9AEh+AEl8////iJCcLAAAAEt0Uk5T
ACB/v+/fEHBQoIAwz0CwYPDAkPSzWJhoiHioSLg4yI9Pr5/vZPrQ+8VmP5wHffZO3NbgKLdCINcY
b/ftbQSJ2ueREg2r/HsDYi3K85OoyAAAAAFiS0dEU3pnHQYAAAAJcEhZcwAALiMAAC4jAXilP3YA
AAAHdElNRQfkBxQLHTYQ2fmIAAAT3HpUWHRSYXcgcHJvZmlsZSB0eXBlIGV4aWYAAGiBrZrncSO9
moX/I4oNAd6EA1t1M9jw9znoHo1GmvvZlUoiRTbRwGuOAWT2//7nmP/hKzlvTUyl5paz5Su22Hzn
SbXP1/PobLy/71cs73vu19fNxxuelwKP4fkz7/f6zuvp5wdKfF8fv75uynzHqe9A7mPg+xV0Zz1f
7yTfgYJ/Xnfv36a9H+j503LeHz/fYd/Bv/4dC8FYifGCN34HF+zz+7lTeH46P4XfPOdCd1/xofI7
hd/Ez3yE7jcB/Hj2JX72x8zCz3A8A/1YVv4Sp/d1l768/mNARenzjJz/uLP/PKNdnbefvz7F75xV
z9nP6nrMhnDld1E/lnKfceEgnOF+LPNd+Ek8L/e78V1tt5OsLZY6jB380ZwnmsdFt1x3x+37ON1k
itFvX3j0fvpwX6uh+OZnUAqivt3xxYQWFrnwYZK5wMv+Yy7u3rfpftyscufluNI7BnN84pdv8/WF
f/r9y0DnqMyds/UjVszLK+BMQ5nTb64iIe68MU03vs48D/brlxIbyGC6Ya4ssNvxDDGS+1lb4eY5
2GS4NNqnX1xZ7wCEiHuDDsSCDNjsQnLZ2eJ9cY44VvLTmbkP0Q8y4JJJfjFLH0PIJKd63ZvPFHev
9ck/LwMvJCKFTNtUEtRJVowpZvqtUkLdpJBiSimnkmpqqeeQY04555KFU72EEksquZRSSyu9hhpr
qrmWWmurvfkWgLFkWm6l1dZa79y0x85Ynes7Lww/wogjjTzKqKONPimfGWeaeZZZZ5t9+RUWEGBW
XmXV1VbfblNKO+608y677rb7odZOOPGkk0859bTTP7L2ZvXXrH3N3B9nzb1Z8zdRuq78zBovl/Jj
CCc4ScoZGfPRkfGiDFDQXjmz1cXolTnlzDYfTAjJM8uk5CynjJHBuJ1Px33k7mfm/mveDNH9u3nz
v8ucUer+PzJnlLpPmfuet99kbfULt+EmSF1ITEHIQPtxwa7d1y5e+vPHduzkBrPDBquUMEo0rs0U
YhslR5+XJxbATfJxrpGDZjm4wte5Shw9ruoGARojNL98Jzo9FTfKGuaknhZRmHXPk+dq5YQEFTnh
NcBafR4xjxVnyG6Agqex7JZqn6mEM5pbVZM0tW8QkqLgJZ6XQRd7H+sc83D3SCTJ7p6pnp4Lo5IP
P3wfkVogwVQHsLy86XEfF2riXXKTV9h+WDB48rEwjrLuHOvba5/URtp+bkuZ7ZTLaHHn3mNpPZgB
7MQEc1FEekKR/6NH87s30l5u5pJSjeWMdU63VFRIhWgdckzjTRDXiidsXLlxmanNHeq8pHFq8UfP
4Pdnliz6hH2HL+VTBVCwZBMsXD3W433euZoNMjICLLmD7tZXmu02BM0OwZX7Kq+NQ1x38IM0cH3P
jXLd6CSYk7wZ3WO7MOZfKcQ/eDTf3+gnV83ieJdqgd7TnekNE90dfZoDLN/02w5zARF7l2Lo/Ox3
XZ3KCXQlMYq2UdzAjN2lTkvJrt4PNL0YB0qe9djcF6Cw06SHaX7FaKXu2pP6JgEYAuEmVcMvwpJo
4H0CCEFVDVcVyH6Y00pjrMX0xl6NajLdrtZmXpbg0vmLew4mzb1PbmF0xEsJdsWyWvTzFKsAULOh
/xoU88/D+32glu2oLa9GYv0ZqVaqkWC7U1eeWVhWTp9n5Hl2GwDWoSm7PT1l0JwQhr5M6PlQmBt0
TblOdWQEAVpbGZhcmeiCm5OezxLDQwhA6NIkCkSES7kL0TRn0QpnZw/GEEXCAa6khJYCt2ZCoy1P
D0SgG/gFww93Sh64Sgs0prZVHFA22TtkaYQw2068SjImhT1plFzqBohW1ozBbu5uyzkIDJpq1D62
zx1CqdzBsORagMVDZqiT2PYoibTeQKLhxl+Mufn3SWt7UjkmnL20yj2o3iPvkVY7We2p95+3nzfv
WwSmVHtgQHENwjVGspiq+YEvGs1dfEnJ9bFIN+/MClOewydhGe8JbrEpn+0jpd2W8l2LOzECbGoO
aLVf2EmyO//o0fz5hXBTsXEEMtkrs3Cr5K5UIYzQ09C0j+uYgnDJDuDwvZST0IiVNIblR96sb9sR
19oxFrKfVSOhzbLyGL5KaQRKtnvkDghJX86Kvof1B4VCJ889NyxxcA4wfquSyNy2ux1a3yOra5dV
MJgt9Voaim0PWmmFXLfQHrFQG9oFGA3Vo1BgY8iAdJRKFQ74jXkD5KpOoCYoj+BfPAgthyCZHc4c
M7gFO1O2ENlELsHB80AfveBWoFdbwKYM8INQewBOY4n4HXRoUp+Qc47EA81EvsMs26UJ+dgDnYyb
ZBQH6wY5H3pBAVwoLNF1VAsCuxm7Tl41nNLGkGoZaEYMEn0PKdeJQBox8rBylcQAhI9menE8B4H6
PFTZNAiJM0nanhmqPm2L+Ww9g1JDgZQVafqSAAlkRgZmIfUpuAVXoT/oEKzCxuCyeQMrB26NphmV
sJkucoP1HUEUNTMiogt0IVzQsH9KDIH4qYrNXyzf1IWHQiOcRIlnI6pQwTkBdB5c2qbVWQj4Bq3s
KoAGpi+hlrqt0kJ9T9gs1iUL2TufQBjjSUumHBGZg6pZFoliELNUykS3IHx6pWY60JCuRaJP21N9
Kcz68Ae++OOxklSUb4ZsoqnoZhDeuoxyX1EwCg2t6UJG+6HHAG0QNPmG4CdCMaPmmBOZTxF42WMP
CwQa2NFmaKyB62fMhVtgbkgoxJP6j2TMTZcp9GiGRQFU3vIISehFgoTKhTiMd3kDM3v5Eqe8cPOs
Hr2fJeIJFgQPpG2EHFgzHtqoZJ0iWQV/sLmMxRvtgcBCshMquyA1h8t++DQFD+ZVrbgjt9VIAYdB
S9DVCCLYLUO5Hdg0kYapwhm0MLXRHNIA7Q9Ztu4LjHBbAXm77B9qQ/P1BYDqEAVEmK8LTU1Ou4RL
piDFNqQGWQzN2it9WCayAm4E/IXLg4UOqWHwKs7iGrQKsp1WaYkD/yLYabpO/LhdFqYw7ZLcbK+Y
Na+myQAmAFbGcrGtTfmoXtrlm6SAAXs9beabMjprooopipq4VXGL2cFrlUIGFnFnPqPHUFdeN7kF
itURllEpTAqMYbw579aCCxgdxluUF0rLm0HjMWOU3Ksiu7uT5dNt4A4AT1rZYy5QK5DAFvtgSlgZ
gMEncQVgSzbrrhCnU5b765I9nnwRCcy5yvvgshMahvzTAhiVYdExMdjQbIR0iisEr+ruVPOoIHHL
Q3s6LROuicOLzQG9cxq87oR9yBxsjAnaIBNUtvW4ohQ4PuxE21ElWnF7oGX8mF97VYC5cmBgYqK7
At3dqnAUBWBLj5xKoSCDCS4tWqXIIAaomqHBaNE3ktMPk1Hy9SXB9pKgfUkQvEbJ132oQy7HgMSQ
lzLOqFgimoQioA1pPgOGAHaVOGxqeLKGQ2tnTziQW6QbpRWzI4K4cLp9yJBVCcp1VYwCwJNiEFgO
Hhm0M8YXx7HPxnOArJQ/i2gHlePRhhSkbAhGk5LDDGITEbDjQN1iEgPO9DUXGj6BJXAo40MAdXOz
DAnE2bRb1xpTb0x9jzY33dC6nmbUH9hH/2Fq0KAIJ4q6MVR/y1IzqD/tCWIoYfFLC2gkCzAjBWQU
dsRUhThHNEOOcmBqLZPmiS1ekYhkiXaX38o0E6ofz80ANo1S5tRi9DZ+2W7kxxhm1U00mWCSZhit
3Yq1Wjj6R3I2SPqh3KOzWDY7BSoLcYCAQQPNjWMn/gbVXsiKZCAYOTo2pssxNeVOexs0QUU/yJL6
k7O0HtWx4arVkeaJuDSkkoGOR6VlMcu0mvY4F04GjgDjxOpC3tn6ih0R2jqt04NDHVA0iyzFXIms
bQm/VtDxNVq5CpQ9An83Fr+XJSlWKoxJSr6kSXJpJD8p26Nto7mwsMHVTMsZvJ2DCAfBQ/MWLRFW
ORFFAf9pt0P0r80JNF6MQy4aSMH6rQklYqabiKQYqIrbMjvZCgwvEiw6Khmqvcizmk9qUu39I59I
Ozad8g4V2ZaotbFD6SmYNfvouLpCA2KXAQTtfiztrlAPF68XXr2ioxASeTTZXIwuZY3V5BfEjvvp
hrghGfH4FZABPlJtQhDGIR529wew/TfjoN0qf3HerYVEMSAPGQPaGbwyaTgIJQA0heHb1SUUkOAd
3UmzEz60ATTpfQhITioY/o7HG9E8qChrVsmD3ZIzUCJFvuVpmWDbVoSL03o3KErbVbAs+Q1eM+gI
BlqX4kEztPJN/Mp4Qfe2EWTCBiamcBadeG0QkEW5DqoUpUj3gybk/roa5C+aG/bSpiN8MC79nSa9
DuGLz0BO2oLasSuoOYZ4EGlvXtUU4NkEzaKYIQOmSa0AX/7SD5eHyzXxGgAqFP07Jc8bVz3UYmb8
oROJQHWNal3ekZaqLUC6O0UCrQ0BJAURJKfANeBF6WbANOAHEtBnAOIaK9ZHsr6ryKLwkLaASOBZ
2gYVlhA6FIbVUQM5JhcoZqiZy0ipK9tTRyuqiCns5FcSLcnpN6kSP29NE5yMPYc01EcAGOo9o9qn
stAd80YVGVCVfnEb7ade3kPJpLm7jjRAcyQHXgOPBOBRuxljbfs9C2LCnToKtHSJwzB9z9wbTila
1WTBss1TOgCCRfLOH9hKag60afIpsEZ7BB3oGK62o4tMgyro0wijMllEwygbfqRIfEMsIEkwBq4O
itP7Cc5ryWvjaiASgWvFanATg2aNG8NLFLCFNhLQzrNAxSdv3bSuhuunMg11gJs6eMLoYBTjQ0xl
A1XFJBiBSA/ZPdlLq5gTT9ooZWxJ6yocfBTUg7vMwuAp02pBXFyLg5EB2GNQ4hTLiDiKpj0MyIzF
14aUABCQAcHF6gFUImkLNF4BK0LI0qjvJrZ32hA1CAARH2iO4JwUIT0O6Km0hSUYQekA1ZdHvt2h
afxayZT3ADQ62WrtBjaQpdZGMMWcJ23PuuGFBixoH5lQ4yD9hreoYieejSh0zLiVr1jY5EV6DaGm
TCCeXC9jUjHwG/yysBn4Huj6aG/y0i9gKDOB0dGZwU3rPjpzuPoIEobHoFsEMSVttSWIzJY/YphQ
sfAru8UoJ1RgWfCCbWEW+YjfbszBbD4spsT8VmQOQskNGAck6kLJNmOEmHR7KDndadIJTL5vihrN
ELBaqQG1nly0e5hLTYOOaAw6yuNfIFZ8KUQoXC9LOgtVhUuLpJ6e39vtD1+B7/c60HolYUKjULkY
ALwAPsf+lOVDrrk34VBGhUwCQsoofTxzl9DSFtG+AhFjHUKyiVk0pAsakHzbqd11QrHhvE3lox/k
Prlp969/iNqrfRGS3HwVc7/XchJNHfgvBIIprwrR0p/lbkThBi7az/BNnwXqEdoJrBklCkrRG4mC
Wz/MjOdvrGw1oA3xANeD5CFdCL3gjVBiUuxTICZHpZmirto5QMS7hTaQgEwThM4eK4oZoNMXZgaL
pMMTfOUIeMqHZL9vzkFd1Hqg1uFUVBuW185A+odADe+T2pwkbTogukGAFhYd6NOuziV5yNy7gYfW
8giHMvOelxNQ0qMZ7IaKT0c1+AdqZdFZ8CvcQ5a1T2tBkhzBx1rnlA9D7Eztsx6ABkOIitjLGTzY
rsytIxHJKlot0EC94HuVGifdtZouHUBwJk38AEEz0LkYsdlADcjXTI80HKS6UsAUhkM1QG0Ffi+3
ahEP6zlFou9pfxQDvhpSRtJeJecCc18GHbePUy9DCJhEWRtgEfHRkFLzbnLSS9QVCg2IsvQPKgMZ
rqMj1IFPU+dCBt1Pzuj6mTWUS7WiXOgSNB6iAztjl9dxG3QHbD/0MSdj/bpXYj5vmnx9xFpYbVEQ
RjvnJpVr+9OQM+C1v+Cis5VSEBxG5yNOYl57B1P/mlHRq0BmATzIPqrk7mXh/kLB1yH4+i2og5fj
wnsE0fWfLN/qrnWaosKw+GMMUX1R/Js/3pf0QGCv2jFUzdBcM52OtgBH0XlEzHXR+2OaKZql7QRo
c/drhgeINjKrKxm3AZ0xkEMLUsYCc9zZdY6a3ep43IYdaa1SsOg8gkyiBe99YB+KtlyI+3q2XBDs
756L6/PIlzYpykUl0tkBHUEg0SsyFNQ96aPFktUWD0741VbQZjV/e9c59fVsMZ6LAk2YCBMbnT94
7UxU1OrSMUDDJFF5QIs2oca93GpDU/9i0nVSjUIfE4ZC+WLVrE6Jq6HiUVJEHnOyELjDganCqzK0
eY3nQfSntl47mX8zRabgw7NVX7Qd74qsB4STznMKlRAZ9xQKp4aC0ilUkIR8j/OYMD4FXV0EGwaO
SSJsMEB67gpyLl9/90jT/NkFkO7EBbUsLQNH0xA6LUFkLaJN96MZ8brTzDG6TA14oG1ZgMrJAE5J
W3Sv/oNgDO1E6J+dsjapXdOJmvxYxehC4Ae3jvKf9CRYMiKiB4peKmiUC+ueOmoGimRGscNYPS/r
jVQuHlcDd1epdkKNkjbN+Rp1yI+c/7QTldCvkOXQrKw29re0n9dgRbIiQRx7DxfaKytu1l5lUR9l
EQSltBvABO+vJk2BMqJqUEX5wN9d268AF+0AmWEZotv6P7YyBHZ4F6RfQzT4WH+CnYgaHOhDwDSn
ZLNHCXtxwbtv33SMbf7F8fUvj18GAh0QpNgbr5MIHcXBHFmbf9kTGajpdhmS8Gmce3I7M+Bk6HRw
wDkPJKKAaQ2Pfsiyb1dQUMhkHLb2FbXQ7mbvafu+C6bRETq08s0gQno7cx6JQe4c5OIPMVxTOwZo
WIum0BGr0z96ycOj69I92RfaoX4lXpP5kDgw8Tmrmf8DUtZ/n6XrB3IAAAMlSURBVFjD5ZdpY5Mw
GMe5obC2BN3l1Fl1rU6tunnNa9N5P0l0fv8PYxIgJFQbRnjn82IHtD/+zx0cZ625nh+Evhc5dhYH
ICxIrDAjkJZaYBJQLOuNiQKVs9GbMwYYRFAqvo5xxekd6kkFwJaRLvWQmjPty0lqOcQuPq7mVt4X
UztGSz02FY0avyYWGCeaCD3Ms8KyUxPE1FxBYzuKsKuwOQDFcbZgexDODuwOwklhZxDOLhSDcLZh
axDOJlwbhLMH1wfh3ICb9pDM83mHbezfsqG4MzHIKG/XUOkN15v5s+7LcaoP1tvyenCp5ViAPlhh
o1QQyxXSCVSrkYO1UuTKtUbvdHCt2V5Y/iV2RlGtI3YZd9ghSH6ZSj0QMAGIUCqWGvuJjBhltzd+
AYzcuweVPsyuzo0c5agh9TDAQvymtLpi1pM3HJYwhsKE8Do68O/hRp4xPpHiFuNgwWCxxfedrLmV
u92z9ROXGSOE4FJBKu/FnbP+CwsZlBDFkwqUmzEVh2kAfHGhFFAZkaxAgAqjU9w4hUeG/NZPU5dd
z3NWZk0pY+mXSUR0qJ9LHvDykw5RqGpofeG5s5B/yPfqCw8fLUVwmlpkFcT/XXuYSmQH+6Xsx09g
8ZRocWEdxUBH6zCF8vGAeXfMWvTZcXSkB5jVNMbPjSmuLXxRLODlHk9uroN44NErGYoTz/f3T5qg
RqH6WYJfw5u378SdFdD7Uzg7LJ/ty+fWYztuPZJ8+CifOVfv5WPn/BN8/vJVGwYAs3IoKtf4JKZE
PTNNZZDyVHz825L5hnSZ5djWnsnS22q+LE4RKlIJ/34KSwwtEL+hZISIyWKYKec/mOYWKdX1lHcN
XRwFvLj14uLdMmqJBMOZMhaUFqho5Yvbeox4e+F+UdW3kOls1a3p8FUlpOVb1K7nuWlF1jWi+8aD
ofZXbnqrkQuAU6ge1EQ2ADLPSqVMKG9eNTnRRAQJdRjcSr3xWVJzGr3jju9pEwUkQ2Tezys2hb9Y
jxN2u05EdjqtILOgfq/SK51kPnV0CHWH0v2nqZULI4tXzkyORNtXzmiajiZp3CdR/6X9AVy3mGFJ
GTXnAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTA3LTIwVDExOjI5OjU0KzAzOjAw2GRSHwAAACV0
RVh0ZGF0ZTptb2RpZnkAMjAyMC0wNy0yMFQxMToyOTo1NCswMzowMKk56qMAAAAadEVYdGV4aWY6
Qml0c1BlclNhbXBsZQA4LCA4LCA4Eu0+JwAAACF0RVh0ZXhpZjpEYXRlVGltZQAyMDIwOjA3OjIw
IDEwOjI4OjUwtr2S1QAAABN0RVh0ZXhpZjpJbWFnZUxlbmd0aAA3MbCAU4kAAAASdEVYdGV4aWY6
SW1hZ2VXaWR0aAA3MRpkJe4AAAAadEVYdGV4aWY6U29mdHdhcmUAR0lNUCAyLjEwLjEwLT3VAQAA
ABt0RVh0aWNjOmNvcHlyaWdodABQdWJsaWMgRG9tYWlutpExWwAAACJ0RVh0aWNjOmRlc2NyaXB0
aW9uAEdJTVAgYnVpbHQtaW4gc1JHQkxnQRMAAAAVdEVYdGljYzptYW51ZmFjdHVyZXIAR0lNUEye
kMoAAAAOdEVYdGljYzptb2RlbABzUkdCW2BJQwAAAABJRU5ErkJggg=="
    />
  </svg>
</template>

<script>
export default {
  name: "CH4Icon"
};
</script>
