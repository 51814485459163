import Vue from "vue";
import Vuex from "vuex";

import home from "./home.module";
import stazione from "./stazione.module";
import province from "./province.module";
import wms from "./wms.module";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    home,
    stazione,
    province,
    wms
  }
});
