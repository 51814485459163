<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="59px"
    height="76px"
    viewBox="0 0 59 76"
    enable-background="new 0 0 59 76"
    xml:space="preserve"
  >
    <image
      width="59"
      height="76"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADsAAABMCAMAAADk3D/4AAABfWlDQ1BpY2MAACiRfZE9SMNAGIbf
      pkpFWhwsKOKQoTpZEBVxlFYsgoXSVmjVweTSP2jSkKS4OAquBQd/FqsOLs66OrgKguAPiJOjk6KL
      lPhdUmgR4x3HPbz3vS933wFCs8pUs2cSUDXLSCdiYi6/KgZeEaAZwhAEiZl6MrOYhef4uoeP73dR
      nuVd9+cIKQWTAT6ReJ7phkW8QTy7aemc94nDrCwpxOfEEwZdkPiR67LLb5xLDgs8M2xk03HiMLFY
      6mK5i1nZUIlniCOKqlG+kHNZ4bzFWa3WWfue/IXBgraS4TqtUSSwhCRSECGjjgqqsBClXSPFRJrO
      Yx7+EcefIpdMrgoYORZQgwrJ8YP/we/emsXpKTcpGAN6X2z7YwwI7AKthm1/H9t26wTwPwNXWsdf
      awJzn6Q3OlrkCBjYBi6uO5q8B1zuAMNPumRIjuSnJRSLwPsZfVMeGLwF+tfcvrXPcfoAZKlXyzfA
      wSEwXqLsdY9393X37d+adv9+AAxYcn5iKLTjAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA
      6mAAADqYAAAXcJy6UTwAAALQUExURQAAAECvjzitijquizmuizquizmtijmwjTuxiTmujDmuizqu
      i0CfgDmtizmtizmuizmtizayizmujDmujDmxjjmvizmtizOzgDmtizesizmuizmtizquizmuizmu
      izmuizmti0CqlTexkDiuizmuijiuiTmuizquizitijmqjjquijmuizmqjjmuizmwjje2kjmvizmu
      ijmuizmvizitijiuizutiVWqqjmuizqvijquizmujDquiwCAgDmtizytjzivhzmtiziuizmuiz2q
      hjiuizmvizmuizmvjDOzjDqvijmuizmuizmtizquijWwjTmuijmuigD//zmuizitizusiDmvjDiw
      izqtjDmuijqwjDivjDmvjDmuizmvizitjTmvizquizmuijmuizquizuxiTmuizmuizitijiujDew
      jDivjDmujDqtjDmuizeujCuqgDiujDmvizmuizitizusiTquizuxiTitizmuijivizqtjDmuizqu
      ijOqiDmsjDmuizmuizeujjiuizmvijmuizirjDmtijiuizmqjjmuizqujDmujDiuizesijmtizmu
      iziwizmuijmuizWqijmuizmuijOZmTquijaujTevjDmuizmujDqtjDy0hzivijquizqvizmuijmu
      izivizivizqvjDmuijmtizmuijqvjC65izqtizivijqujDmuijmuizivjEC/gDmvjDmuizqvijmx
      ijmuijmuizmvizmuizmuizmuijmuijmuijquijmuiz2tjzauhjixjDivjDqtizqtjDmuizmvijeu
      jDmuijmujTmtizquizmuiziujDmvjDmui0m2kjmuizmvjDmvizmuizmujDivjTetiTiuizivizmv
      izqvijiuijmuizyujTmuizmviTqtjDmuizmuijiuijivijiujDiujDmuizquijmuizmtizmui///
      /zG5KuoAAADudFJOUwAQg9n56686J9/+dwhRZ9ayIcmzJGzbClou54Y5yvvvkwwXkUgp92VtErmh
      G9otDstirc4yp0ED80ZY/MMC9CIgfdCrFdWi/bMUI4rCyKQd3ckB1UQrUE0fdJvZb+7hTqVC0r0s
      Guz4V1sqSX5d/DwGcdi5zDSNDYh41VSumw94oLdhe9LpSZn+Ceb81IglxfA3psQYdVUFhSYz9vg1
      EXZPXOjYQFaovHBMZgtLaZ5e3PEEr8YwSJDyu+20gWs/uuUZE1L+7z7x9UWd3+nisFJZ+gfN/nnR
      /mAc4tuPU8fjL9c2n7/l4IxovsGxwN6xI/VEAAAAAWJLR0TvuLDioQAAAAlwSFlzAAAN1wAADdcB
      QiibeAAAAAd0SU1FB+QHBw4MA1HUJCEAAAG/elRYdFJhdyBwcm9maWxlIHR5cGUgaWNjAAA4jaVT
      W44cIQz85xQ5gvGzOU4PNFLuf4EYDPPa2UibWEKIMrbLpki/a02/hqlhgmF4glYlbUbANCFtehkb
      CrIxIsghRU4EsIvdbWs1gDx2TJqVjAy4CbEKwz9Y96qDUd5AI2x3Zj+09MP7TZ22kUahjAtmSN4Y
      GFq0lHU5SM18QrDxcgSe2fkePo6FnzVwrMnHOccYjvYIeMGvesftCd+EHB+J2F8mqGJew0bwgM/4
      N/fToGqoLc60HeyzcAGsFpzdC84bt90aJ4erib6/0n7JqqKXiNAOWG5P4CMELr6chIxXG43qUJr4
      7g71S2PI2Nb5ijtDEoIrAb4TSH9nkM8HA4FVDKPYLEJRhNkTaR8fwld+MIDHl3iyoWB4E7BrSokI
      nwWZoXJESrv6MEKZZyuTYW8FJn4789zbqkStzkTVWXxioD06o6NHQiofmSKXHoz6MV/jppU/JsRi
      02/H3PPRHNDssrm3ORLtyaO6zbx4WgxBygykkBmccnvRXRjtRHBSm4HCITCudV4spZyrQiTuUr/T
      3R7227PD/nNfhPcfiV6FmP4ACqMgMuX4uGMAAAQuSURBVFjD7Zf7X1RFFMAHRIIVBKRaXVFAEDDJ
      B5kSsChIrICPVTS0gMwMFAExKnzU2gsfaPlAQfCBKKACVpKFmlJaaaXkK1HzVdpLq/M3dOfOzJ27
      u/de7vpbnw/nh/2c13f2zMyZO/cipCxu7r08ens+gh5CvLwBi6GP66hPL6Di6zLbl6Hg5zLrL7EQ
      4Crbj7MuL1cgZx91lX1MQh93eb5Gb8b2Zy4f3fAAE0EHUjto0ODgkFCd8JAwgQ4Op9bQCDyQf3fV
      RoZFDSPqE2Bi3uF61jz6SSHFNELUwwBGUvcowo7WZGPEnKfYYo+h7qcJO1YLHRcra6ZnAOKof0w8
      9iaYNdlEwo7HxgSAJBZIFs7UxBTtpfIU0WdF3RwBqdQdYIFJaSwnPWPylKnhzuw0K2anzxCNTJhC
      3e4AM1lKpIc4foyRmLOey5pNI6Fznn8BIJsWkUOc5lzwZnN90UA7bi62XpqH1Zfns4FfyYN8cZcy
      ABYszCrwQYsACmnQbJVaPQihomKixi5msK+wSyUodMmrJFBc+hpYWF2v8xP2BvLyYLqpjMFCGy1d
      1htksnwcDa3gvjfRW9xYwlibNzjKqGhS8kruehvlcuMdabnfdWIht0A4IO/JsoUO9eDG+wwNMDmz
      UL5qNT5Na6TB1qIKHl0Xs9CG0ZL1oCYVH3y4gWgbNyG02S5WXJm9BW1VIyurqoUHyLYcIc+/RviT
      WguLbC8V67f026GC5tFWqp6/cxfR6mhk8G7kVpUaCxoS5dTD9Xuwf28D6cnGpnhVdp/zAfAJ3x+1
      mG0+SjOosvlG7VOIgjSKDuqGrddgD5B5rWhOt0NaWpvJ0+mgBvtRVq0bmiAcpfyPZeiIT4RQKp50
      HGhL8CH8m7hLQtsyRf+nGU2H80CXNEjsZ7ryDZ/3n9M+8Yioj5fYBhaOsB49psomiLmtuBNT+XRt
      iWLwC3ydHFdlT5DkjqYv97fJ1ioSd8RXJ7GakqiCHlF7QDfEHG4/RdQMFbYOdS8tmYqoX4kOFn39
      jQL6rZce9PQZpb/97nsdaOt2gB/OOpDnhD6sEu7nnV6Kl2Hb6NJO64/nL1wEQx0yukfIV3jg2mwD
      FK/GN8iepEtOaPRlkvcTWJqxPe1KF3FYLifhbS+6yEbaUOaAGrtY6Oo15rve8TPcCGe7elMqY7MD
      24dXeIt7k+E2U813eF//Ys/+ytlARfaubP6Fdugl2VOqU5FtlLH37Kcri1QosgdkGSH2NfPZwG+S
      c9jv8Icb1YdYeEaRPfsnj/zFfAX4srrD3oI9pYRyh01Kke6+rhLmqxTt+9SaXc6W2ekjopbCfuz1
      AI0kVRpsrAyyF2cWOPUVSrkp0NZC/qZbRln2Vomq6+c++HtrGlIUW4ud+Y/IlqKHkZpOAf3X5S8V
      WsfB9r5639Z7pEd65P8k/wFMpiF0IvjkRQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wNy0wN1Qx
      NDoxMjowMyswMDowMBJ+i0wAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDctMDdUMTQ6MTI6MDMr
      MDA6MDBjIzPwAAAAG3RFWHRpY2M6Y29weXJpZ2h0AFB1YmxpYyBEb21haW62kTFbAAAAInRFWHRp
      Y2M6ZGVzY3JpcHRpb24AR0lNUCBidWlsdC1pbiBzUkdCTGdBEwAAABV0RVh0aWNjOm1hbnVmYWN0
      dXJlcgBHSU1QTJ6QygAAAA50RVh0aWNjOm1vZGVsAHNSR0JbYElDAAAAAElFTkSuQmCC"
    />
  </svg>
</template>

<script>
export default {
  name: "NOIcon"
};
</script>
