import { WMSService } from "@/config/api.service";
import { GET_FEATURE_INFO, GET_FEATURE } from "./actions.type";

export const actions = {
  async [GET_FEATURE_INFO](context, params) {
    return WMSService.getFeatureInfo(params);
  },
  async [GET_FEATURE](context, params) {
    return WMSService.getFeature(params);
  }
};

export default {
  actions
};
