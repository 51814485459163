<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    enable-background="new 0 0 40 40"
    xml:space="preserve"
  >
    <image
      width="40"
      height="40"
      x="0"
      y="0"
      xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
      AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABblBMVEUAAAAGMEIHMEIGMEIF
      MEIAMEAHMEMGMEIHMEIGMEMFMEIGMEMGMEMFMEIGMEMGMEIGMEIHMEMGMEMHMEIFMEAAMEAEMEQG
      MEMHMEIALkYHMEIGMEMGMEIFMEEEL0EHL0IGL0IAKT0AAAAGL0MGL0IHL0IGMEIGMEIHLkEHMEEE
      LkIGL0IFL0IGMUMHL0IGL0MGLEMGMEMFLkIEMUEAKEMGMEMEMEMGMEIHMEIAKzkAJEkFL0IAMzME
      L0AHL0MGMEIHMEMAAAAGMEMHMEMFLUEGMEIGL0MGMEMELkMGL0MHMEMFLEAHMEIGL0IFMEMHL0MG
      MEIHMEIGLUQHL0MALEMAK0AGMEIHMEMGL0IAL0IGMEMALkAAJkAEL0IGL0IGL0MAKysGL0IHL0EE
      LUQGL0IGMEIHL0MGL0MFMEMGL0EHLkIFL0IGMEIELUMGLD4FLkIGL0IGMEMGL0MGL0IAK0QAMT0A
      AFUALTwHMEP////fyjP1AAAAeHRSTlMAf9/vjxBvgMCwkKCvYM/Qn7/w4DAgQFBwFpbVzGZHudgZ
      AZ3Ik8WxJ0tC/mwqvrQuhWQ/E1tFtdwSB10KPLj6uwLW4TOL/ew9qOU063c13XvBLeIXDPbmeBuG
      HBRGqfQGg5REh/vezl99TTaqOSky1/yC7h4VAxEX83hXAAAAAWJLR0R5odzU0AAAAAd0SU1FB+QH
      CgwhKCysiz0AAAHhSURBVDjLzZT5U9NAGIbfJl26aaTJpt3YSlXEWpXIjQq1KqiAgIoX3rdWvO/j
      +/PdJE0bhpCiv8g7mdlndp/sfptjgSAZTc+yEFlW1zLoch9iyRE3KB9ynkxOuZD3+NwfEwsWYOgh
      6yZgFUL2b7Z4TCTuz9pmNRuPc1tkthCCdCE0EkFIE0KPsxoTagMGcc7J4Z1QcStbZEJdQDFWR7Sp
      Tcy1oAy102wpGjRjzPU+GQKFIjPIiQYZJytiaRHfJKph2V1Qsi3cFXvkL0XpPzIXxl7YIjGsLbpk
      cUMi76LME1IktyO6acvmdpFY2TdQ3a/aAwcHzUMYOlyr1Y4kivWjxzJ0HMPeiZHRMYzTxOTkVKI4
      PI2TdAqnZzDbqOAMVUrNbWo8e+783DwuVOt08RIWFpdo6XKy6C6vOKu4QlevrQ3g+o2bI7fWEsXb
      wPqdMRTuAvfovt/zgB4miI+8x8BgHU+ePsNzb/bFS+BVq5kgzr/eGH/jvcU7mng/U8UH/eOnlbnE
      pT9/oa92A/j23dv4gQpvtUant3kzPxtB8+t30DTX/9dHof4qOeXHThfLpPrbSRVZP1g554f/S412
      JiWiI5aoR2R0AEg3NTI8APLYQbijTjaxg6hTVBpOrxKJHJP9AZa+ovUnsPDOAAAAJXRFWHRkYXRl
      OmNyZWF0ZQAyMDIwLTA3LTEwVDEyOjMzOjQwKzAzOjAw2H0O0QAAACV0RVh0ZGF0ZTptb2RpZnkA
      MjAyMC0wNy0xMFQxMjozMzo0MCswMzowMKkgtm0AAAAASUVORK5CYII="
    />
  </svg>
</template>

<script>
export default {
  name: "CalendarYearly"
};
</script>
