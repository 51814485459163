const state = {};

const getters = {};

const actions = {};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
