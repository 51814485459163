<template>
  <v-app>
    <a class="skip-link" href="#content" role="link">Salta al contenuto</a>
    <v-main id="up">
      <router-view></router-view>
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Footer from "@/components/layout/Footer";

export default {
  name: "App",

  components: {
    Footer
  },

  data: () => ({
    //
  }),
  watch: {
    $route: function () {
      this.$nextTick(function () {
        setTimeout(() => {
          const focusTarget = this.$refs.routerView.$el;
          focusTarget.setAttribute("tabindex", "-1");
          focusTarget.focus();
          // Rimuovere tabIndex: https://axesslab.com/skip-links/#update-3-a-comment-from-gov-uk
          focusTarget.removeAttribute("tabindex");
        }, 0);
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.body.addEventListener(
        "keyup",
        function (e) {
          if (e.key === "Tab") {
            const focused = Array.from(
              document.getElementsByClassName("keyFocus")
            );
            focused.concat(document.getElementsByTagName("a"));
            new Promise((resolve) => {
              focused.forEach(function (element) {
                element.classList.remove("keyFocus");
              });
              resolve();
            })
              .then(() => {
                if (
                  document.activeElement.classList.contains(
                    "v-btn--disabled"
                  ) ||
                  document.activeElement.classList.contains(
                    "cursor-pointer-off"
                  ) ||
                  document.activeElement.classList.contains("disabled-card h3")
                ) {
                  document.activeElement.classList.remove("keyFocus");
                } else {
                  if (
                    document.activeElement.offsetParent.classList.value ===
                      "v-select__slot" ||
                    document.activeElement.offsetParent.classList.value ===
                      "v-input--selection-controls__input" ||
                    document.activeElement.offsetParent.classList.value ===
                      "v-toolbar__title"
                  ) {
                    document.activeElement.offsetParent.classList.add(
                      "keyFocus"
                    );
                  } else {
                    document.activeElement.classList.add("keyFocus");
                  }
                }
              })
              .catch((error) => {
                //console.log(error);
              });
          }
          if (e.key === "Escape") {
            document.activeElement.classList.remove("keyFocus");
          }
        },
        false
      );
    });
  }
};
</script>
