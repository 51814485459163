import { StazioneService } from "@/config/api.service";
import {
  FETCH_RILEVAMENTI_STAZIONI,
  FETCH_INDICATORI_STAZIONI,
  FETCH_STAZIONI,
  FETCH_CATEGORIE_INQUINANTI,
  FETCH_TIPOLOGIE_INQUINANTI,
  FETCH_PARAMETRI,
  FETCH_DETTAGLIO_STAZIONE
} from "./actions.type";

function prepareLista(data) {
  const lista = [];
  if (data.length == 0) return lista;
  data.forEach(element => {
    lista.push({
      text: element.name,
      value: element.key
    });
  });
  return lista;
}

function prepareListaStazioni(data) {
  let lista = [];
  if (data.length == 0) return lista;
  data.forEach(element => {
    lista.push({
      text: element.extraInfo != null ? element.extraInfo : element.name,
      value: element.key
    });
  });
  lista = lista.sort((p1, p2) => {
    let s1 = p1.text;
    let s2 = p2.text;
    return s1 > s2 ? 1 : s1 < s2 ? -1 : 0;
  });
  return lista;
}

export const actions = {
  [FETCH_RILEVAMENTI_STAZIONI](context, params) {
    return StazioneService.getRilevamenti(params);
  },
  [FETCH_INDICATORI_STAZIONI](context, params) {
    return StazioneService.getIndicatori(params);
  },
  async [FETCH_STAZIONI](context, params) {
    const { data } = await StazioneService.listaStazioniPerProvincia(params);
    return prepareListaStazioni(data);
  },
  async [FETCH_CATEGORIE_INQUINANTI](context, params) {
    const { data } = await StazioneService.listaCategoriePerStazione(params);
    return prepareLista(data);
  },
  async [FETCH_TIPOLOGIE_INQUINANTI](context, params) {
    const { data } = await StazioneService.listaTipologie(params);
    return prepareLista(data);
  },
  async [FETCH_PARAMETRI](context, params) {
    return StazioneService.listaParametri(params);
  },
  async [FETCH_DETTAGLIO_STAZIONE](context, params) {
    return StazioneService.dettaglioStazione(params);
  }
};

export default {
  actions
};
