import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";
import PMIcon from "../views/icons/PMIcon";
import SOIcon from "../views/icons/SOIcon";
import NOIcon from "../views/icons/NOIcon";
import NOIconBlue from "../views/icons/NOIconBlue";
import NHIcon from "../views/icons/NHIcon";
import O3Icon from "../views/icons/O3Icon";
import COVIcon from "../views/icons/COVIcon";
import CH4Icon from "../views/icons/CH4Icon";
import COIcon from "../views/icons/COIcon";
import CO2Icon from "../views/icons/CO2Icon";
import NO2Icon from "../views/icons/NO2Icon";
import CalendarDaily from "../views/icons/CalendarDaily";
import CalendarYearly from "../views/icons/CalendarYearly";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      pmIcon: { component: PMIcon },
      soIcon: { component: SOIcon },
      noIcon: { component: NOIcon },
      noIconBlue: { component: NOIconBlue },
      nhIcon: { component: NHIcon },
      o3Icon: { component: O3Icon },
      covIcon: { component: COVIcon },
      ch4Icon: { component: CH4Icon },
      coIcon: { component: COIcon },
      co2Icon: { component: CO2Icon },
      no2Icon: { component: NO2Icon },
      calDaily: { component: CalendarDaily },
      calYearly: { component: CalendarYearly }
    }
  }
});
