<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="71px"
    height="60px"
    viewBox="0 0 71 60"
    enable-background="new 0 0 71 60"
    xml:space="preserve"
  >
    <image
      width="71"
      height="60"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAAA8CAMAAADygAyiAAABfGlDQ1BpY2MAACiRfZE9SMNAHMVf
U6VFKg5mEO2QoTpZEBVxlCoWwUJpK7TqYHL9hCYNSYqLo+BacPBjserg4qyrg6sgCH6AODk6KbpI
if9LCi1iPDjux7t7j7t3gNCsMtXsmQBUzTJS8ZiUza1KgVcEICKIMEZkZuqJ9GIGnuPrHj6+3kV5
lve5P0d/vmAywCcRzzHdsIg3iGc2LZ3zPrHIynKe+Jx43KALEj9yXXH5jXPJYYFnikYmNU8sEkul
Lla6mJUNlXiaOJJXNcoXsi7nOW9xVqt11r4nf2GooK2kuU4zjDiWkEASEhTUUUEVFqK0aqSYSNF+
zMM/7PiT5FLIVQEjxwJqUCE7fvA/+N2tWZyadJNCMaD3xbY/RoHALtBq2Pb3sW23TgD/M3Cldfy1
JjD7SXqjo0WOgIFt4OK6oyl7wOUOMPSky4bsSH6aQrEIvJ/RN+WAwVugb83trb2P0wcgQ10t3wAH
h8BYibLXPd4d7O7t3zPt/n4AaypypEnnTzsAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADq
YAAAOpgAABdwnLpRPAAAAURQTFRFAAAA66Mz5J835qUx5qYz46E34qA246E346A24qA156Q346E3
46E35aE14qA146A34qE35qA3//8A46A246E346E25KI34qA246A146A246o55KE246E24qE246E3
46My554x4qA24qA2/6or6KIu5Z4146E3458046E246A24qA34qA34qA246A24qA346E24qE346A2
46E346A246E246A35KI35KA25KE25aA05qI36Z806p81758w358g55844p825KI14qA346E24qAz
46E34qA256A176Up4Z8246I146E245w54p83/7Yk4qA37qoz46E346A246E2/6oA5KQ346E346E2
650746A26KIu5KE246A346A246A34qI246E246E246A246A15aM146E346A346E246A24qA24qA3
////vMHrzAAAAGV0Uk5TABk4HxSH1PbPjyrzyEQ+7bszAXb843DwW/0JE+ug+yQV+vgGCx0uQFFj
dIaX7OiAsOPJt6aUg3FfTjxFGBAIIPVg9/4j4OgrH+B47BJYB98Po+K9Axzbig28Fnra9Zk0ZIi0
bjpnYJ9WAAAAAWJLR0RrUmWlmAAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAAd0SU1FB+QHFAsgJImI
wH4AABbvelRYdFJhdyBwcm9maWxlIHR5cGUgZXhpZgAAaIHtmlmS5DpyRf+xCi2BGBzDcjCaaQe9
fJ0LRtb8nlqt/tCHMq0yIplBEvDhDs5y+x//edx/8GU1VZes1NxyfvhKLbXQeVOf9+t99U+6P+9X
+vqb//m4i/Pzh8ChyGt8f8378/nOcft+Qkmf4+Pn4658LhTq50L+24XvV9Sd9X59Fvm5UAzvcf/5
3bXPCT3/sJ3PvzA/l/1c/NffUyEYy7heDC7s6OPz/nzvFN9/nX+Fn7zng/4eibz2aDH9Hj/3LXR/
COCyP8fv+VpZ/B6O90Jf28q/xOlz3Nsvx78uqCj9uCIfPh8J3/+gr3H815p+i985q56z3931lB3h
yp9NfW3xvuODg3DGe1rmu/DPeF/ud+O7Pv2ZZG2x1eGewS/NB6J5fPLLd3/8vq/TT5aYwg6F1xBm
iPdYjSW0MKNSkPTtTygutrhiJTeTzEUOh29r8fe+TffjZpU7L88ng+dinjN++na/HvhXv3+60Dkq
c+8VzGU3VqwrqGRZhjKnn3yKhPjziand+Hr3vjy/fimxkQzaDXNlg50k3ksM899rK948x8ccH03P
2y++rM8FCBH3NhbjIxl4so/ms39KCMV74ljJT2flIaYwyIA3Z2GxypBizCSnBt2bc4q/nw0W3sPA
C4mwmGmbSoI6yUrJUqbfKiXUnUVLZpatWLVmPcecsuWcSxZO9RJLKlZyKaWWVnqNoJjVXEuttdXe
QovAmLmWW2m1tdY7N+2pc63O5zsHRhhxpGEjjzLqaKNPymemaTPPMutss6+w4gIC3MqrrLra6ttv
SmmnbTvvsutuux9q7cSTjp18yqmnnf4ta5+s/py1XzP391nzn6yFmyh9rnzPGodL+bqEF5yYckbG
QvJkvCgDFHRQzp7qUwrKnHL2tBBdjBZYpSk5yytjZDBtH+z4b7n7nrm/zJsjuv/TvIU/Zc4pdf+O
zDml7ofM/Z63P2Rt9Qu38SZIXUhMQchI+/GBXXuoXbz0L726P/2hZdbY81hjVL1dscwVUyVDeTSR
i48Vril186mRl4/b+bpHjHuQb+tztcTxXtpaO8/hbZKYVrbFOksoY8O0fea+SofTFAFiFVra2Z21
EhBsHCKmi8YM6xwRyPCjD8XIjzMrCT0EjgtyMb+es8320CfP02uZLh79Ek/rjSheauD3WvuucUbQ
G5Lno2X1sSdFNycXbKtx8ZNZ6Bpt5vh0LjTY+DydzFqgI5SquQnOkzPpOyS5pjTggwUHVLbTSsor
5VipzzA3H+n7cdT7bCHtWL21Zseedawnqoj7FssxtGysD0irlIwagnJnd/vMVZ67fu3NpUdvG0cT
fHf2LPp9UvvLz7WfFblhX7uapYmCiqfQJ9Mo10Nz+VAIdt3LjbTrOjPucMLee87+xFkil6n2NDXV
ZDslUtensIVh/Txrc2nWFAgMyYl7HkcFc0CroHEORQG7HVI7i3lbJxVPREaZPqwyip1Rt/WWAi16
avONPvWovuQaifX65Xl+fC0k95nnyeXMwAUzNZF0uxHKamXexLccNxlZFO5xq/s6+djKI/Zh2af+
sJOSU+01BYuthFlKbNDvYKPmSdjqhD0b3b6DAQTPaA5KtEq9RwJGGhAI22uXD0sZpJrqKicrESGM
ZYTyOeDHQz1BKz2SW08LICIETMPAw1R3EjCSqdzQumYjlgZ8rBZG6ZQX2aKRAlfmHXGLhVK1vgDf
5rxwWGW1USfAJ/2SFuID1I+s7KFcI5C2j9FKxGtwcitnAcznAJ2mtdJKDiyjigWLNForUWEAiWZJ
6JaVQLo1e6FoVF2h+3XrbJcT07zBP0OX9M4bfTpOLZ6urmyabqH3ba46qGIwhAjEQYJafnok36jS
5tce4AOLoLEosTlcEYSCw6HR1TOzGOIFelKKvj1CnIdaSJRz3z1xXuZi9CEAvUaJ2wZXWye6mbiC
X4EGW6yH2Iaa50kTZEFF+AbjJJtDEnARsLUj1+/0zQFvfDuVAgYjCPa43dYJYD+bBKsGhxj2lhqH
/NwKNzgGBgwgw2zRaiuk0/0uK8+wkuu5jV1LGG+pFz/nLXCTO/i82qIaIrn3vZ9KU3H91VqhJh8h
GHUOQe4VAMe26DNfV2dlg3+pbyOPi1X3XH0gGzG1zD2HPaWwziQJBM53n1j9Rh8V3yZ5oszW6Fba
aU3oQ0GxtVBYMTTVD+CEMlwUdmKFRLp7ypI6ejb9rKZtmW0GX6E08zVv4JCafOYKWSKaa1fSwQd8
RNeWoiDYicU6EATOt573Lm6UtEHdxlF7+98aiu1beODwhTU8qR0P/XlSAjHTblR+gcFB6pJofzfI
4MAClP70ZWwZP9SQ4JENEAKuG9ABnJbJNe1FOz4UIYrei/JGOlRMys0FADk1XSlnW3NO5IXfq4IW
UBhh7FR650Oxbc/SALJGBC1AMzuGPuEYlOtyk/AmSJ1Axny4HGV+YKOVd0bK3JRkeNPy9NIw/eI/
zEYON0RPZ65tp7kK/A0ufGjVSQi5q5qH2xCAdWO2ged8cRSxUdOAuwu4OxP37Wwjzt4eNymuIsmE
NKsp82Oinjtb6z3TYQ9SJq72YDFLUJfS0v1MkHxJkEEjCwpPwc2NFEfg0OsZxmgeMJPwmWmjFlIQ
77NmD/msltHqc55A51LFRpCQYdAhms5VhA8o3UAMOpvoDnAIlKOVGiC8TRoEf6eg0IlUIUq3si4P
QlQ6O7FPNJXj4kBORP7TOIlIv7VU/fgDu7T5lheRKkQU6xAIHiDUt+OanA3uk6gBwVD8ZHVDwmG1
SRo+blpdPujFgyIykKj1g8i4EQOS8nGtU/PgX5VE4DJymFdCDdoADEZOopXYCOkCvZcvEEP0vKUz
nkVqyC/t65A6AHKH8obPG3SdOR+zQwUAHHnagl+2+g02b+JU4OBUKB8yGlQSQVuoTXe26hwYqWeL
nBvkTLESJECW4F61ZBmqAOv7c9l2J0QGJWyIZgQu1ban22AMDB/pMfPQSh2X3jNKpgOPXsQwAFN6
/5yGfL0sQpF/uMnnl5ucDkNPV0ZAUFRzJtAn9jOW4G8ZIq+c4uF3rMLMdP0qu4yl2NK2IcGjBh4t
2t17FDQ6Cr1W04GtAy3Eyi9zEQ/hxJIRJ8g/RLw0QC+WJxNXl3KAFqDregNJqgS4a5Ps2Y2OHZRs
gSpBaUnkV0XvxPl6AxQSGZDF/QD0+gh0MlEmRO02ZqrtsBz0MlaJyt90LwKD4p+AaURTkpKCXH5c
D+BjAnYFCxXaz51uavXQG7GiiZY6F7IjFLQ/DR4SQmbuhIVDNswdNUKBjtglus8gOlhxhTBFwiO3
jby9Yq5KOoeLm/Qh9cGqn2Neqn+if1HNCEIHMskmAS5PQA+C4IgnErsyQinCeNSq2gJ/EGxsNDpo
NahPFFgZj8CGxETvKEDwW/IfHmZfqPFTEzWDGkBZszPckUqjUl5QP+yGJkN0k7FMPaARSW5SjIjx
2PySlRFDFeX2nDv9gvjj2/nzJgtWfOo38zPaWYmeigAoLhuF3kCkM27xR5AaR3dUkUgHzZnwQVND
Q7CR6OK7A2rzynTqonroDX/RkMfL74T8WOQch4FSeRALmE32gW5giehkkkmvo98hQ+qNeqHS56IU
kN1wALlxEJE6GIs6DcMDTdK/DdNJlSRhL5rDiN3aYOftPiQve3ruqkLhZaBPjjvUBBranitucKho
QpLGiQaOU4EbYX4xIEbux/6QWBFwRJwKVegVuld+TdpIYhgrS2Nnb3IHtHyTJoc9xgFCAQ3UL6Eg
Blc5wgdQCC1oYHZC37m4oqfhAyy2UQ1wToNikVsYsolaMpAagElXE4+jjI0iaw/A5HNiXkRxreqQ
jXz+umyE8baADyBmhQNUY7EBaiF257mckJ/fVFi7BVRx2fxUw6hc/H5hC5zdiQXY3Tjv9Qp8IQb8
p2bH3aKqVv69HIdIBv0CoDxMBAVcE2DMQtMMgFh1zqTRj4pkETyC1m9iMGLcKaGf5lwdnT0n+nUH
v6jGzpYaKKHaoVHpAWqaLdOpW8KHGEU8Tb6ooMJCaS0QS3YdOIAsFjoJp6JCppnRv547WJuTQkcY
UV+UAh0N1NnsG5RMcGHECJZR8coB8OdvbB9Ds2gFZDqMhmVEVvAJj8ku2CKPfI5tVs4ltJCMpNjA
AsMf3JQ1uv2GH/fwvOF/vr++ONpeDQjcwVjIJtwRnQo7I1LhLrQQZr+I+2vD0vmJ00OB3FhERA8b
lqFdlIIwymOJNyYqkWE2i8veqmrUJU0DTzrps6IbHZOb5x01jLxGweBXCWQOeJQ+W/NEEB5Dab6k
CdPKHR6cMkXjnks+L6uBi5qx7IYWQMPuhIKlmVmzoTMDlPrW2R1iyOd/XD7nAiMy+jAwDM4SYf6K
waB0HvQDxikCtFhOQBwswvyTt9TbJLKewoezkGCho2gdUBVA0Ul8UAkGRsOfi5x5Oge9iCwUF47n
T178h1f3GJkHvNnFRdAXoW8HfOqfGmb3aoCBuZ0IfkwZvGMTYynCZhMWHSZtITsgY7KLSiwVjY2p
QsHllE/EGAXyeGaUT+EfCcEbAPI6QIFrwJFmdpG2WpUwd/n0C26VRrmZwcXcIUqn6z7t2l+Kedv1
G8mwWAfnQqV3pME68HNI+aNCfGducK7E8W+1+usr+ki1ynLL0mBHnC/Zv8/c64RuLWXk1M7oUFTK
0JCgLy+ex0UmFAySSnamu0BdQZK4a0+XaYoKHVHbOJ8RkHdYHQSKaskjVtHVUgxICRbSVBVTNtlq
YGscBMLwY59E1r8YEP72ii2n8egusN1ch1JI00R/3HFAEcaRDwgTfrozGcCWmAJo7YY5PZ8qoMMC
6npGLHp2SjcmurNn9k5HavAnpJk953cottXXn6lYq0KsUyQ9Je42bbuR7sFJGSPTs4EHKB/u0VJr
De2pJ28biMK5C4LieeimWReC4KYdSFJpeKlrexzei43QjVjFJAQCrDI6GcvBOZNq/ZgKwHj/eWh1
X93XG/gMfSCMByrfjl5NfbFw7nz31wSICjLWqq6T4ivRK5JgVNd3kZXEpKHCkBypR6Qe5kdzBYA1
SJVgn2DxHln3yAt9hikbcB40grwgOhVZE4emYAvlZOM+8UINFROKHNohv1UPA/mLX1XaCr2ARI4f
HfLKEPfP6ZCVaecYBopMBExLjC22bue9EVmLeMOXR9narZN3bClFkq7kM0qjUSVy5qiRuxeQNkKL
kv0IFjYdnR7ccu9Mo/QjEa7hX6Og8KnSUwgyGoer0yXBEIkzPHAW+lfOuTQ9ulDq3N+A3tfr3ysC
f3thOCtowGmPtGGLGldAyoOs4tcC1Im6xLthOzJBQXQWk4Ou/KDEEcYcICRNXsS8wC5YvkhHfBBA
SYN2oov/WFICsNWQfV1kWA8Wt69UDFe6k9gtXnuk2NVfGuhA94iuW+hztpLpPoACdMZBJHoMDGPR
rANLqGmUHrBBwMBncAm1zeLxWUfu1Frm9Iqm3xkXPHrAEaDzjFVG1OirylHB+RdUcX+AG2S0UTmc
T7eAm2qSpohmsG00qPcZfoqgqiL1DhNdAlvELuk+m7mzYg0iIb9B9+D+zriUhPWMNt6pMZKjoMak
GAHZVdjqcJrMoy+nhujDombrWbMEQEhmoLK2l+LbbRH4QawRthoaCiv1Myl181PPA+N0uwpTsDUD
LPIaoDGrOvsgS/AZZBbaulfAPaBpkS7tVtNVIzq9Xi2t+0ON71OA+D4F0KxXjWMLCKaaC+vNOCo9
vogSm4iFZ0wXG5ITDp6GEQ8iBATUTpqGey+w5Nywl8bT6O4Gz4lfZXbpcSQNna+Z6wSPsN65kRiu
Bstiloih58dg8Ugp2BwfRVPZecB7H3E4USIHgH4GXw2oDuYeL8xAWqK/WtWAuEJdtCsdPcqDjlRd
a5CCnH3JNyJcfuNa9+1A1IQPSsXEHJwc0KFRlVydqrGhlMGosVCxUCgs5CHyrYeWVDSmzL1jCyL8
aPoQdyKfCSuAwqsroKBT1R800LgMMq/OT6+4wD9M9Tyc7LgNnTIVolS31rGw4sO/CInM4s/jqYk1
BbqPhCOn6DOoHAiJoHddT7HigAIQJtUGdZci9Ew+9IJ+SJcWRIPQ55FuQOThAYz0eT19WB7NAa4P
1ZzTHDD6XDSfp09aTAKgp1b/yOXLLKWGtUcuZhrgRuFUVt38Hczg6oHeehy2NYFraO7QtOipMQOM
0UkU4Ialx7egmIl4q5qqBz86hVFmJI2DeyPTYgnuTvaf0naFzYkNJdDM9y67kq9DGAOFSkyTx0q2
8A45J5T/k3VzX29qQgBlzdcASphlcenRi62hIWqb6Azq9+g/Z1CR9ckpPBTtkYkJFDH6CKCS9D+E
DBc5NBo3cVFtId8BjSlgHcnl06W8XRWx7vG9KFlIEvqpTjpP4+1ruh6ENeXT7uQThNfzRc8qNdDb
dzZHxaCHOn4Yx9GA3S28YhcO7WZ6WIVgLD28Fa8BTp3fERM40kQHIIjwpqTtwBzmBXgiBWWRl7E1
WAbIZu9gBlLrkSXoet5JTYeJePYy2nUMPR3bekyi0c1KemCPKlnT4wweh1Kx7UVS9Okm1EMeh234
O9DEogX0dEycT/yQJ8AQMmmn+x9NGux0QRx5bPehAMwJSYJ01NEK99kL1IHRW8tox4DwotSwX3W/
iAg0bBBXT0Hqxkq6qKemFC1cTiMdkStcEzSG5wZ0COfdwQHtlzR21GzqmOwjdqysy4C+NacnApTL
1H8ZyeAiQN41ShdBxKrniEtPD8od08v3l3fch+ZocmKvPArLHdhrvPIIDXfkpqmVR3ZR8ijI741B
XyAKeKVTEXBirVmR168gYvNykAEl8DrIPLDtlvTYFOl57iySTLNvYoPz1hOjV4Hsyzwq4Y0T1aSJ
9HN2kZIYogRwhArVA1l0NUzlMYwkgmt0aT9QrA6Ab9AmBFSTYM0vWIKjpfFFuIgjHZqltei6+qwW
NZV79BxrS3kBvFxtpvtAsCeJwzMKUcV0mEcfpRdyVAI47XZEjaOm+SCEYAsogp1BP+AAPADmBix3
+N4FY24oA1PzQj/na9g7cZY9yUDRlcSLdQB7miOVXMeTMDDgrOkxLNbAgqQl5brCcPOaPRSMxt+3
Jzyqqw/BLejLCuupmTsUsDCgvHuCSqizAm7UTRT131vq4wr1HZHFGx0PndzxMVjEwjr18YBzEw+G
DNGID4/YnrknaptqnJxYLovS+U6XwL+jG1FEDzcDLPjWyGjxi54xDJqcT6tgpWfZ08Z+D1hvtCSK
XrF0h1ws3SP22Q22pqznx1neb6O89NPx833E57BOABmwTlVOTpdqQEtSDgiZhiiSwkEJgPaGbtvA
uB7WUkmAPGSXtSW0hgsnNik4PYQ64UGH2NZTafmOBOh+nMjO14kgqmGylVcaodHor8eEbarT7KxI
5caqIQEUxgdIUpbmgA6CChcCJ0qi0Dtjb0A4dFn16GsIiHF+DjO93v9OQWf7+/AIqUAVwjsLBdIp
N83tKwWBjBj4xobPfFCGrYsIkSqgsCZacq2d3ZaGW4I9GleiCGU/1AbpEfQeYwEGvsqxNES5JCVq
GyGXWIpfrn0ehYJQ4a+N5seUdI/OGahfGKnQl3hKo3N8FIvkiESHlCiU5MU+Hiv69PV52CNEWXrY
M1QDkL+mTes+ss4WoTrsn9yzywdrCWOgMoy4oLe6RKnOv49XfryCIrnMNFfP2MGwMNIH/oUQkH6a
23ZN1QkhxnNuGYx2HzTccXy//4mSnWHwEJF6tGJ60JRVCeVjwYcHj/6bwPyTr+5/e4H/v9D/qQsh
XlfDGvwXbIw3X1akPboAAAFwSURBVFjD7ZLXVoNAFEUxatQEYxejYtfYwd4T0Wjsvfc2GfD/P0Cl
LSR3BiZ50bVmv96zzmzuRRA4HM7fpSxSXnpJRWW0Cn1TXRMrrSaOXMTaoqUTdegX9Q1FSTc2NSMf
LTGgJlC6FRUiJdil25JAEYq2s0p3dCIQmVVahntQVzebdE8vocijVLS0RV9/GOmBwaHU8Mjo2PgE
ymMdg7kUTRrrSMfSpG+kg0UKTRp42zDgHqTaRSI0/Pwpw1PTM7Nz8wuLS8sr2CAuySmSoGHe3Iji
7noVkbGPskaJqG5RmpKyn8tQi9yrrZOvptGkzavpG2GutpmlSTNcTXQ+fwuaWlfbdq+W29klff8e
9cc2r+ZIm2iEnv0DJ3FI3LUoeFHhkOYGjo4DpU1OwNwpk7RF9qwwc37BJG1zeeVLJK/TjNI2N0rc
m7iN+APB0g539w/2/PEpB8wDpT08v7y+ye8fhGmgdHiCpFmgS3M4HM6/4Auh8vCqZueSJQAAACV0
RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wNy0yMFQxMTozMjozNiswMzowMLD6boYAAAAldEVYdGRhdGU6
bW9kaWZ5ADIwMjAtMDctMjBUMTE6MzI6MzYrMDM6MDDBp9Y6AAAAGnRFWHRleGlmOkJpdHNQZXJT
YW1wbGUAOCwgOCwgOBLtPicAAAAhdEVYdGV4aWY6RGF0ZVRpbWUAMjAyMDowNzoyMCAxMDozMTo1
NPG4MPYAAAATdEVYdGV4aWY6SW1hZ2VMZW5ndGgANjDenFJeAAAAEnRFWHRleGlmOkltYWdlV2lk
dGgANzKDbXRUAAAAGnRFWHRleGlmOlNvZnR3YXJlAEdJTVAgMi4xMC4xMC091QEAAAAbdEVYdGlj
Yzpjb3B5cmlnaHQAUHVibGljIERvbWFpbraRMVsAAAAidEVYdGljYzpkZXNjcmlwdGlvbgBHSU1Q
IGJ1aWx0LWluIHNSR0JMZ0ETAAAAFXRFWHRpY2M6bWFudWZhY3R1cmVyAEdJTVBMnpDKAAAADnRF
WHRpY2M6bW9kZWwAc1JHQltgSUMAAAAASUVORK5CYII="
    />
  </svg>
</template>

<script>
export default {
  name: "COIcon"
};
</script>
