import { axiosPublicInstance } from "@/plugins/axios";

const ApiService = {
  post(resource, params, config) {
    return axiosPublicInstance.post(`${resource}`, params, config);
  },
  query(resource, params) {
    params.data = "";
    return axiosPublicInstance.get(`${resource}`, params).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
  multiQuery(resources) {
    let requests = [];
    resources.forEach(resource => {
      requests.push(axiosPublicInstance.get(`${resource}`));
    });
    return Promise.all(requests).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;

export const StazioneService = {
  getRilevamenti(params) {
    const offset = params.offset;
    const limit = params.limit;
    const body = {
      periodo_da: params.periodo_da,
      periodo_a: params.periodo_a,
      stazioni: params.stazioni,
      flag_validato: params.flag_validato,
      formato: params.formato
    };
    if (params.formato == "zip") {
      return axiosPublicInstance({
        method: "post",
        url: `ariaweb/ariaws/rest/getRilevamenti?offset=${offset}&limit=${limit}`,
        data: body,
        responseType: "arraybuffer"
      });
    } else {
      return ApiService.post(
        `ariaweb/ariaws/rest/getRilevamenti?offset=${offset}&limit=${limit}`,
        body
      );
    }
  },
  getIndicatori(params) {
    const anno = params.anno;
    const keyStazione = params.keyStazione;
    return ApiService.query(
      `ariaweb/awws/airdbService/reports/yearly/${anno}/${keyStazione}`,
      {
        headers: { "Content-Type": "application/json" },
        data: ""
      }
    );
  },
  listaStazioni(params) {
    return ApiService.query("ariaweb/awws/airdbService/stations", {
      headers: { "Content-Type": "application/json" },
      params: params,
      data: ""
    });
  },
  listaStazioniPerProvincia(params) {
    const keyProvincia = params.keyProvincia;
    const beginDate = params.beginDate;
    const endDate = params.endDate;
    const includePrivateStations = params.includePrivateStations;
    let url = `ariaweb/awws/airdbService/provincia/${keyProvincia}/stationnames?beginDate=${beginDate}&endDate=${endDate}&includePrivateStations=${includePrivateStations}`;
    return ApiService.query(url, {
      headers: { "Content-Type": "application/json" },
      data: ""
    });
  },
  listaCategoriePerStazione(params) {
    const keyStazione = params.keyStazione;
    const beginDate = params.beginDate;
    const endDate = params.endDate;
    let url = `ariaweb/awws/airdbService/stations/${keyStazione}/sensorcategorynames?beginDate=${beginDate}&endDate=${endDate}&publicView=true`;
    return ApiService.query(url, {
      headers: { "Content-Type": "application/json" },
      data: ""
    });
  },
  listaTipologie(params) {
    const keyCategoria = params.keyCategoria;
    const beginDate = params.beginDate;
    const endDate = params.endDate;
    let url = `ariaweb/awws/airdbService/sensorcategories/${keyCategoria}/sensortypenames?beginDate=${beginDate}&endDate=${endDate}&publicView=true`;
    return ApiService.query(url, {
      headers: { "Content-Type": "application/json" },
      data: ""
    });
  },
  listaParametri(params) {
    const tipologieSelected = params.tipologieSelected;
    const beginDate = params.beginDate;
    const endDate = params.endDate;
    let urls = [];
    tipologieSelected.forEach(tipologia => {
      urls.push(
        `ariaweb/awws/airdbService/sensortypes/${tipologia}/sensornames?beginDate=${beginDate}&endDate=${endDate}&publicView=true`
      );
    });
    return ApiService.multiQuery(urls, {
      headers: { "Content-Type": "application/json" },
      data: ""
    });
  },
  dettaglioStazione(params) {
    const keyStazione = params.keyStazione;
    const beginDate = params.beginDate;
    const endDate = params.endDate;
    let url = `ariaweb/awws/airdbService/stations/${keyStazione}?beginDate=${beginDate}&endDate=${endDate}`;
    return ApiService.query(url, {
      headers: { "Content-Type": "application/json" },
      data: ""
    });
  }
};

export const ProvinceService = {
  listaProvince() {
    return ApiService.query("ariaweb/awws/airdbService/provincianames", {
      headers: { "Content-Type": "application/json" },
      data: ""
    });
  },
  listaProvincePiemonte() {
    let url = `ariaweb/awws/airdbService/provincianames?codiceIstatRegione=A`;
    return ApiService.query(url, {
      headers: { "Content-Type": "application/json" },
      data: ""
    });
  }
};

export const WMSService = {
  getFeatureInfo(params) {
    const url = params.url;
    return axiosPublicInstance.get(url).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
  getFeature(params) {
    const url = params.url;
    return axiosPublicInstance.get(url).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};
