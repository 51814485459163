<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="65px"
    height="75"
    viewBox="0 0 65 75"
    enable-background="new 0 0 65 75"
    xml:space="preserve"
  >
    <image
      width="65"
      height="75"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEEAAABLCAMAAAAYshoRAAABfGlDQ1BpY2MAACiRfZE9SMNAHMVf
U6VFKg5mEO2QoTpZEBVxlCoWwUJpK7TqYHL9hCYNSYqLo+BacPBjserg4qyrg6sgCH6AODk6KbpI
if9LCi1iPDjux7t7j7t3gNCsMtXsmQBUzTJS8ZiUza1KgVcEICKIMEZkZuqJ9GIGnuPrHj6+3kV5
lve5P0d/vmAywCcRzzHdsIg3iGc2LZ3zPrHIynKe+Jx43KALEj9yXXH5jXPJYYFnikYmNU8sEkul
Lla6mJUNlXiaOJJXNcoXsi7nOW9xVqt11r4nf2GooK2kuU4zjDiWkEASEhTUUUEVFqK0aqSYSNF+
zMM/7PiT5FLIVQEjxwJqUCE7fvA/+N2tWZyadJNCMaD3xbY/RoHALtBq2Pb3sW23TgD/M3Cldfy1
JjD7SXqjo0WOgIFt4OK6oyl7wOUOMPSky4bsSH6aQrEIvJ/RN+WAwVugb83trb2P0wcgQ10t3wAH
h8BYibLXPd4d7O7t3zPt/n4AaypypEnnTzsAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADq
YAAAOpgAABdwnLpRPAAAAaRQTFRFAAAAWDivVzevVzetWDiuVzetWDivVTWvWDitWDiuWTiuVzet
UDCvWDiuVzeuVzeuWDiuWDivWDitVzeuVzeuVzeuVjavVjeuWDeuVTWqVzetVzitgAD/VzetVzet
TiexVziuVzeuWDitVzatVzetVzetWDiwVzSuVzetWi20WDetWDGxWDatWDetVzetVzetWDetVjes
ZjPMVzetVzitWDetWDeuXS65VjitWDetWDetUzisWDitVzevWDauVzeuWDatVziuVzauVSuqWDet
VzetUzCzVzetWDetWTWuWDeuVzitUjOtVTmxVzeuVzavWDeuVzetWDeuVzetTTOzVzeuQEC/Vzit
VzetWDWzVzeuVzeuWDSsVjeuVTOzVjitWDeuVzeuVziuVziuWTOzVzeuVzeuVjetVjevVjWtVzWt
WDeuVzauVziuAAD/VziuVzWtWDeuWTOsVziuVziuVzitWDiuVTmvWTeuVTauVTmqVzetWDitWDit
WDeuVTevVTmqWDiuVzeuVziuWDeuVzetVzitWDetVzatWDitVzau////gPVWeQAAAIF0Uk5TACBv
j7+vfzCf319wEKDPkMBAgO/QsFD18Rj6uwLwzRrTeGDL/Pk3LN4RqBpag6y1hkoF58r6iwtE/fRN
4EaI2tr4VQbIOCX73UjD9hkk/my0mOi4CtgE5MdDT/sx+x52wvXNpBT59nNcPjXWXtYB+2rrKPK8
/GU2RTkSaq5OdDMJnd3+3gAAAAFiS0dEi/JvR+AAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElN
RQfkBxQLJwoaH1t2AAAPD3pUWHRSYXcgcHJvZmlsZSB0eXBlIGV4aWYAAGiBrZlZcuS6EUX/sQov
AXMil4Mxwjvw8n0SLA0tye+5264KiSWSBQI53AFy+1//PO4fvLLX5nKRVrVWzytr1tj50Pzzeo7B
5/v7vvLryN+/nHd5vC5ETiWO6fmz7tf9nfPl4wuSX+fHr+edzNc47TXQ2xPjc0j2ZPu8XpN8DZTi
cz68/nb6+kKvn5bz+onzNexr8K9/ZyEYqzBeii7uFJJ/fj9PSs9P50f4zWduDPdMSo3fJaXv8XP2
11o/B/D905f4+beZpY9wuCeyrxvqlzi9zofy5fzbgBalzzMK8f3J8fOMtvrtP78+xe+c1c7Zz+p6
ro5w1dei3pZyP3EjhZGfaFTewk/hs9y38m6++0nWFksdzg/+0BCJ5gk5rNDDCfseZ5hMMccdhWOM
M6Z7riWJGmeyFGR7hxPFJU2LXMQ0yVzidHyfS7jPVXseD2s8eQXujIHBAt/45e2+nvjT9y8DnWNl
HoJvT5woC+YVrWSZhmXOfnMXCQnnFdNy4xvcc/BfX5bYRAbLDXNjgd2PZ4hRwkdtpZvn5Ivj1uyf
fgmyXgNYk/MsJhMSGfA1pBJq8BKjhEAcG/npzDymHAcZCMWVuJhlzClVktOiPZvvSLj3xhKf08AL
iSip0jaNBHWSlXPJlX5rlFB3JZVcSqlFSitaek0111JrlWo41SVJliJVRJqo9JZabqXVJq01bV2j
JmCsOK0q2lS1dx7ac2eszv2dEyOONPIoow4Zbejok/KZeZZZp8w2dfYVV1pAgFt1yWpLV99hU0o7
77Lrlt227n6otZNOPuXUI6cdPf09a+HVtr9k7Wvm/jpr4ZW1eBNl98lH1jgt8jZEMDgpljMyFnMg
42IZoKCj5cy3kHO0zFnOvMbkUiqRWRZLzgqWMTKYd4jlhPfcfWTuP+bNEd3fzVv8KXPOUvf/yJyz
1H3K3Pe8/ZC11S/cppsg60JiCkIm2o8bduuxcUdU1qlrAkDwZk2GiFNj7XPp1sUizzh7riCqI4Ux
dmUgdVzLZxACDRWKqDPFnkdYo4aLPrr2opdOY4hN8UiJY/eRy1ncm/uWIGfpcHIOM0q7SDpHto4D
BNoTx3lmGofap3HyOjuU3RhEgNGlxZ9dyVHULcXZaD8N1sfUMIHHVuLNcViFRPnWGbF3KqPHrUcK
qVipDLe3UlSaxmpVWCxBpzgmkz8SSz/a7hNPLnUQ8SaiPi8BYUgkAbA6YbDpDODPlLTiiHXM3jVv
yWtRBPRDCbnAat4O+S+P7m9uYB55j3SGrJOlrl2pyUFFUOGpTdqqC5Er6n5M9RhzefqiSaYOKVka
ZdbNgr1lMIVN8UlLM/V+SpynWrAJoZY5EsstnlyMni2S3gLb9+7051AkhPaRbKEna2iU/QBnvdVe
1AZm30VAGJKL0rOpt1oQIGA2QS0UySaq0voCPdqK9kPnems3ll4lCtEdtbu5lzBmrHArPR8zzZmG
+I6gyWfORLFG+pjI5B7PZLxUYGHIMm8lJJEnTonOBFDbvcPAmbBRI0dp2Vnr32fq89F9v1D6SnI2
PFPFCrWsleyY463rQhn3Cli2Uqed32N0OW43vrFCHYsiGtsDOCrhCeJYBTx7a+Yvxx0Ak3JOpC9p
Imeq5Y7bIiW5R/nl8terdBHpnxU0oY/mXHs/C6kOZr9Pp4R+8xhX1TFrBxbPgY6UADzLb37nSpvM
nQyotcSck9Wknx02b30fimQ0rXtkbbECSOD1GiMK0k8A+bA6TUujkWAgYomgrmp7Om2jyj4lBBZf
T2aKL3utKRRHO05glxqBcLIQJtILpKkAuZXu8KkNIxNSJIAvkvHmrzO7OfoA0m0gWXS6W2Lc5duh
AysjmXzcWrIFlp4rAJP13kgJ7H+miHr8nkr3c26/HanvnAal7Bcxw/vsonE9jUy8cnCTbKa+auFR
kmkMeIw4EAaQED5TtM8D3pRCJB3oow3b1AV/D9F66AjfwSMwhzB2ryUpir6d0vdg5NPpFkCHnp06
EIF1RaOIeXbingPyAQJdyshtLGIU0tBK72klvwAE0wkKRRKxAh0e9O7orIJ2bnOTo82CJ0WTfF/o
A+pnUFyO7o6o4dlz6NYQ2lHjicroyDglyAj8lFAJ0uDU3MjuBs4uwtHhzJq1t3kcQDUpq5pmITeR
W6j6qWsYGY7QpxFApW0iMGjQV8jiXlwNqqApg90qw4oiP568gm9/fCyOUqHHT5y4MRoVYgRZ645U
ayKuMUFinYwSuNn9PkHwv0gMqC8bYp95loGOS8VqFYw9ho8LZkc+XJaNgdsAHbJf6LUaIdI0Dr20
jYihFkQUQLxQTWu6uSLijczxTACYSC4KL6JeEDSjbaogxF3jQx/Nz6SAFn0Ux7Tb2vGnDtRINCRE
h02aOtH8gRvMzgrKijpEzxTjnsMQUI0eNY2RYTkeEs8g5mUC5t41kqwjlko97wxuNuZX8HyrN4IA
O8Htvo9u+cQV4VaQfp6EghMjAve7mW7B1KgRT5hgYEsLyFUBkWh/8KTmRBOQchALiGx58S0oJlBX
SmsBqBOCWb1kdzrT1Z3BOvody2YqBEjYYGxHvxgggheDVABN5C4D9TRJOlCoqaN1k9Wc3g8kEXFx
ddCbRDK8znnfq1sgSPTUSyNBx7SPXal1eRhcYneQGs6TtEZurJ9vVxAIJkXqslA6Fujd4B/NDR+i
/FnXbvA39K27mKo1Ibf0LFqDbCbgegC3VbLXTgTiJlhigUD5nriQ7Ig1SmXAuaxE2iq9QdmsWivd
CL2+qCX/AbO4X08Qasjy4Dt0BDuzjiBebJKN7qyeMFOiVDpFdRoeBJEGmE9g5JxK9Ltp1QS6hZU7
apdayhsWBpjAxmKbPeObCEMgWDopUmzWHKuDXcZVAd7iVSgpEOjkvYC6uZnebNnEFCRMvc4TKEQA
i3hM9OuG1Y53tBIOAX3tVySkyICS1i7wnBYsHWMUuwzi5kPVXtlAbFlABUolVG6aPNpdmsj/pXb5
fhQTyYBFdN7wwEiPnn4jv4GJQNmeQ3SIK4Djs11baLgCBgGwgqE+uOXMtJoF0vXpb0R/VC5UfE4P
Nl1xYtLkCpMEOAn4ixlMeBx+O6Eq5YBrpDMB1/SY5Gk9AYvIBJrQdA0WB0j7XmYa8Ekv/aTzXRW4
PwrKDUIkKqz4iUl3T0hs2013UCIzMJKbEyS3ICIKBN0VpXAymQTm+PL2Ba6uTdd6eYOa3FtNhyuY
awvGKArPR8oLUCHcYAfoNy12IUzAsknJUANyBHVZM1ZFpiMysMICztEfCHdi05CXEXtSR8K8ZKN6
UzVAEcx/NIt0yhQUmzAdwgVZf5ZL+fD1DzGInf6P+vOvjs4+GLZNU2aUaUaIw6ULo6nBtg+gU+tK
HkEaKedOHdvWmXkInSZQsAFluXiajMK3MFdcGWKmmeDQv0jtbBCNCwoHCAftIRUEy2TqvVEa5STc
dGTlxaVnQfDIB//6qqk/UrEU22Ag2WrC/ViRo+IpcaoKWLEOsA0DP931Do+DQdsMNINPE6RE4ghf
BYBaRbcAPh1nmZkgan4K0AWBU+1ToIgE94OdQC/fnIgOCBLX9cRw5vw7xep+uoB7VSF5cDwDx7Xh
QeqMfAdACe7asoRywU51tQ1S1IZ7eGNdKYFqSKgzTArL2USpVOK7EXEg5kIlY0unqYwD3Zx9KCYj
rYUS6I4GPrAdjtlOCqSA1UAfEKV7ZjDYYeVJoZ5iHN7EZvDrBBTwh3IUpzkLNNPVwgVrUUa9AOWK
58oLfCTjlI+tFBS2zZ15QONtcmcUiIqBBA1tFESaWZdt1+AycBz4zw3t5zhbQzokQzfAPlNL+HxU
hTUojYR/w4AgtCikbuYYyk89IlehP/RbRtUqWnGYroDlO05d+Uyg0l7p2WLBs1LrkM4eDu4214p6
8HsPo8i0ChQLaEHi1UJHFKY1JIV4zDuavMOUv1TB7GZSjjPb2VAd0RTTeN86wSIkxI1JW8zA0jWv
ARz10QXGMglXgLen4pgzDnKhuJei3qt6wIMWCCMYq06zbzJYn1klntCJ2EaDs9pjYnQmAeFKXWjA
TffrNmWQRxhDMBfgIaBdOZj/UxMdMK0CTNyO+5zxRwb7wWX/LWrntEMb1DR4tm3LQMNOrjSomSWg
bTfIjYqSYoTbbc8EyddNFrdjZm3NXLPJCEDFQoeoxSINKwmkH3ejxqj1shpCdRJ0s5tEv5hs+DCe
d0vAk45IJRyG7BkAC3ADyFca8viAVHIQDfRgPTEGD4Kf4JPajid8OllMIcoUgDRUwmZ6DRKKGBGq
zyOkkzrbJTfByG2000oG5gGzS8YBfOpkINlY3TXeRoogaftBb7nfEmYJ3olq2wtZqUKcu6Gl4nnh
/p3EapiL5bkY3i8iFwIe7HCKTrIAlhtCkTa/CgbnsWi1Vx8WOHaSCYIXJtPB5Pg2iGHcsYekKyIq
rS2UuhlfHNJdsdvIrj8iss/HZS3yeiaJvoJAbIMD3QEhN9aBLWkmCAygoAsaAsCyrZeAlcZyTGt5
+Gy7YwY+o7wpjxU64uFtyyeE78aylHUlF9yN2rwykKq1o8s8FG1Kw3Hz5jRVCDV5LHqJRllq/3Uz
R7xkd5wZnMsbdd7zpD25tC6LFKZSf4t4ft6vdAvDjDxjqgebHoOVLM5mFgQ6UxuGBAm+BmltHygg
wAdNJymaukXJbB9QXt4BunAiLhCmoEmwMWj8hOYGTEByg38LyApG+ANPB9jMjU0EsASMvbtvAail
ZelxQQRl2pjcUDLIrTvl0m6YcX8JegfswK25z7D/KigcIWOngVcIrAARAWaQ4QEhVXxQR3ZGGEXA
1eGxpbYljs3eFmk5AmllQA05i5HEQ25wxeykAxXywjfgzpgKQLWQsBOjxGJwITOTLuMviDIuacXI
DcYFshvEoLYbcavEmXjCZf6vWXvbhk7QLTKWOjDNePdRcM3PXn9pEHQct+ywhybcBxB5NmKmtt6R
FXGpw2tf/0pnY/zvlv2LqfTF63ePYo5LR/razp8v+YA3Nk2IVXOKaohnIKyQ9FBkuLcsYq+kB9hJ
aN5l4gMFonOnAKXCuLESJ9BBfafYUnImHMgVcjHYP3MYr2uDo5K9xR4wvwkv+2fDUvrr337TSogp
mW/EAAACtUlEQVRYw83X+UMSQRQH8IEwRVAEwTSjxCLTTisrKjWSDrMoNbq0m+y+7KK7t+5K9le3
Mzvsxf6w3/UX3w+Pn+bDzJu3M7uMbaQIhTdFIi2bWwOPbyMZ0fZAQIxsEYnjQAc5ohMmEuSKCAi0
UlPEMKGrWYhCQIg8IokIMS+hAxFSXkL3ugVChIQXkF7nVhB1BaykYiZIiJvAiqrIBLZUxprBiiYS
UQgSeqSgqboiEtYO9knw0TxtwaagVyJtrUPl6+jtAwWL0GNV3UrUvw0lWCorhvN92L5jgCgHCyw+
mNGR7M5dlN89lNcTTsjQ+2vPsEiBiX6iESPt3bf/wMG2Q6OHQaHvCNEgT0fHZG2zx45jxIkCnRw6
pYmqyjg9jhETRJNnRH+aRPEsJEyVzO42o3AOIs5f0BuLd/eqRVyE9mZa/3NNE8kiEohwyejuRjJi
5rJ/4EqZ1Dp/2HkKdI9dFQ+IIpMZ1/wLs84jsxFz/gX34S2don+h5AJUWQz/bxXzDqCukip2pHDd
t+C6xDTjDKaK/1X0uAQFbqkbN+2Asa/6RJAnI+eoo1iESrcAgA3POKpA9Ff/vY0IbNzdDQr4ZMm2
tAtlojsYEStb6+BbqS1QN3gVLraYxl2e1vSjFz20791/0EsPHz0OV8XN/AQuhS3iUV6KJV6KajL8
9Nlz/FZ9wa8OZYGWXhrrqrxCF2S8a63JqvJ4vYgSGbmx5pkzlnsTpBR24u0oOIl3qiBsJ+f7Kiak
xQNS/2frd+x1bdnj7P0whQgpowqOC4SmEaHSKGTdJkAvvkWjIRT7FGgCEebII2bRVTTFCCKUvARo
FfNeAvSVn/QAoC8pxj42C+Cb8/InNxBFv+8/F5xA4QsI6IQDKNZggLFa1gIGvgYAGPv2vVMMz//4
iZ+1jajWfrX//hN4+IaN//BjcQHmPOcsAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTA3LTIwVDEx
OjM5OjEwKzAzOjAwae+nNgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wNy0yMFQxMTozOToxMCsw
MzowMBiyH4oAAAAadEVYdGV4aWY6Qml0c1BlclNhbXBsZQA4LCA4LCA4Eu0+JwAAACF0RVh0ZXhp
ZjpEYXRlVGltZQAyMDIwOjA3OjIwIDEwOjM4OjUzEtTq3wAAABN0RVh0ZXhpZjpJbWFnZUxlbmd0
aAA3Nbftl5AAAAASdEVYdGV4aWY6SW1hZ2VXaWR0aAA2NQQS0LYAAAAadEVYdGV4aWY6U29mdHdh
cmUAR0lNUCAyLjEwLjEwLT3VAQAAABt0RVh0aWNjOmNvcHlyaWdodABQdWJsaWMgRG9tYWlutpEx
WwAAACJ0RVh0aWNjOmRlc2NyaXB0aW9uAEdJTVAgYnVpbHQtaW4gc1JHQkxnQRMAAAAVdEVYdGlj
YzptYW51ZmFjdHVyZXIAR0lNUEyekMoAAAAOdEVYdGljYzptb2RlbABzUkdCW2BJQwAAAABJRU5E
rkJggg=="
    />
  </svg>
</template>

<script>
export default {
  name: "COIcon"
};
</script>
