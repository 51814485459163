import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMoment from "vue-moment";
import "@fortawesome/fontawesome-free/css/all.css";
import "./registerServiceWorker";

import "leaflet/dist/leaflet.css";
import "./assets/stylesheets/application.scss";
import vuetify from "./plugins/vuetify";

import VueMatomo from "vue-matomo";

Vue.config.productionTip = false;

Vue.use(VueMoment);

if (process.env.NODE_ENV == "production") {
  // Configuration VueMatomo
  Vue.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: "https://ingestion.webanalytics.italia.it",
    siteId: "aW3g6Wl0DR",

    // Overrides the autogenerated tracker endpoint entirely
    // Default: undefined
    //trackerUrl: "https://ingestion.webanalytics.italia.it/matomo.php",

    // Enables automatically registering pageviews on the router
    router: router
  });
  window._paq.push(["trackPageView"]);
  window._paq.push(["enableLinkTracking"]);
}

new Vue({
  router,
  store,
  vuetify,
  icons: {
    iconfont: "fa"
  },
  render: h => h(App)
}).$mount("#app");
