<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="61.298"
    height="51.502"
    viewBox="0 0 61.298 51.502"
  >
    <g
      id="Raggruppa_14055"
      data-name="Raggruppa 14055"
      transform="translate(2167.91 8308.552) rotate(180)"
    >
      <circle
        id="Ellisse_372"
        data-name="Ellisse 372"
        cx="10.922"
        cy="10.922"
        r="10.922"
        transform="translate(2106.612 8278.303) rotate(-76.646)"
        fill="#e0ca0e"
      />
      <circle
        id="Ellisse_373"
        data-name="Ellisse 373"
        cx="11.922"
        cy="11.922"
        r="11.922"
        transform="translate(2123.408 8303.045) rotate(-76.646)"
        fill="#e0ca0e"
      />
      <circle
        id="Ellisse_374"
        data-name="Ellisse 374"
        cx="10.922"
        cy="10.922"
        r="10.922"
        transform="translate(2141.612 8278.303) rotate(-76.646)"
        fill="#e0ca0e"
      />
      <rect
        id="Rettangolo_162250"
        data-name="Rettangolo 162250"
        width="2.592"
        height="21.941"
        transform="matrix(0.809, 0.588, -0.588, 0.809, 2153.989, 8272.922)"
        fill="#e0ca0e"
      />
      <rect
        id="Rettangolo_162253"
        data-name="Rettangolo 162253"
        width="2.593"
        height="21.941"
        transform="matrix(-0.809, 0.588, -0.588, -0.809, 2133.087, 8290.673)"
        fill="#e0ca0e"
      />
      <rect
        id="Rettangolo_162251"
        data-name="Rettangolo 162251"
        width="2.592"
        height="21.941"
        transform="matrix(0.809, 0.588, -0.588, 0.809, 2148.989, 8272.922)"
        fill="#e0ca0e"
      />
      <rect
        id="Rettangolo_162252"
        data-name="Rettangolo 162252"
        width="2.593"
        height="21.941"
        transform="matrix(-0.809, 0.588, -0.588, -0.809, 2138.087, 8290.673)"
        fill="#e0ca0e"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "SOIcon"
};
</script>
