<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="75.552"
    height="65.498"
    viewBox="0 0 75.552 65.498"
  >
    <g
      id="Raggruppa_14050"
      data-name="Raggruppa 14050"
      transform="translate(-399.988 -8253.436)"
    >
      <g
        id="Raggruppa_386"
        data-name="Raggruppa 386"
        transform="translate(399.988 8253.436)"
      >
        <circle
          id="Ellisse_35"
          data-name="Ellisse 35"
          cx="8.732"
          cy="8.732"
          r="8.732"
          transform="translate(0 47.737)"
          fill="#c149bd"
        />
        <circle
          id="Ellisse_36"
          data-name="Ellisse 36"
          cx="8.732"
          cy="8.732"
          r="8.732"
          transform="translate(28.712)"
          fill="#c149bd"
        />
        <circle
          id="Ellisse_37"
          data-name="Ellisse 37"
          cx="8.732"
          cy="8.732"
          r="8.732"
          transform="translate(58.088 48.034)"
          fill="#c149bd"
        />
        <circle
          id="Ellisse_38"
          data-name="Ellisse 38"
          cx="8.732"
          cy="8.732"
          r="8.732"
          transform="translate(28.219 46.33) rotate(-80.808)"
          fill="#c149bd"
        />
        <rect
          id="Rettangolo_1523"
          data-name="Rettangolo 1523"
          width="24.383"
          height="1.975"
          transform="translate(36.903 35.377) rotate(-89.662)"
          fill="#c149bd"
        />
        <rect
          id="Rettangolo_1524"
          data-name="Rettangolo 1524"
          width="28.447"
          height="1.974"
          transform="translate(10.237 54.011) rotate(-28.467)"
          fill="#c149bd"
        />
        <rect
          id="Rettangolo_1525"
          data-name="Rettangolo 1525"
          width="1.975"
          height="28.972"
          transform="translate(40.843 42.009) rotate(-61.698)"
          fill="#c149bd"
        />
        <rect
          id="Rettangolo_1526"
          data-name="Rettangolo 1526"
          width="44.397"
          height="1.974"
          transform="translate(12.228 51.174) rotate(-62.921)"
          fill="#c149bd"
        />
        <rect
          id="Rettangolo_1527"
          data-name="Rettangolo 1527"
          width="1.975"
          height="44.397"
          transform="matrix(0.87, -0.493, 0.493, 0.87, 41.451, 13.032)"
          fill="#c149bd"
        />
        <rect
          id="Rettangolo_1528"
          data-name="Rettangolo 1528"
          width="49.596"
          height="1.974"
          transform="translate(13.302 55.142)"
          fill="#c149bd"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "NHIcon"
};
</script>
