import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    name: "home",
    path: "/",
    component: () => import("@/views/Home")
  },
  {
    name: "cookie",
    path: "/cookie",
    component: () => import("@/views/CookiePolicy")
  },
  {
    name: "accessibilita",
    path: "/accessibilita",
    component: () => import("@/views/Accessibilita")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("@/views/PrivacyPolicy")
  },
  {
    name: "dati-qualita-aria",
    path: "/qualita-aria/dati",
    component: () => import("@/views/qualita-aria/RicercaDati")
  },
  {
    name: "inquinanti",
    path: "/qualita-aria/inquinanti",
    component: () => import("@/views/qualita-aria/Inquinanti")
  },
  {
    name: "salute",
    path: "/qualita-aria/salute",
    component: () => import("@/views/qualita-aria/EffettiSalute")
  },
  {
    name: "fonti",
    path: "/qualita-aria/fonti",
    component: () => import("@/views/qualita-aria/FontiEmissive")
  },
  {
    name: "sistema",
    path: "/valutare-aria/sistema",
    component: () => import("@/views/valutare-aria/SistemaRegionale")
  },
  {
    name: "emissioni",
    path: "/valutare-aria/emissioni",
    component: () => import("@/views/valutare-aria/InventarioEmissioni")
  },
  {
    name: "modelli",
    path: "/valutare-aria/modelli",
    component: () => import("@/views/valutare-aria/ModelliQualita")
  },
  {
    name: "monitoraggio",
    path: "/valutare-aria/monitoraggio",
    component: () => import("@/views/valutare-aria/ReteMonitoraggio")
  },
  {
    name: "piano",
    path: "/politiche/piano",
    component: () => import("@/views/politiche/PianoRegionale")
  },
  {
    name: "misure",
    path: "/politiche/misure",
    component: () => import("@/views/politiche/MisurePiano")
  },
  {
    name: "misura",
    path: "/politiche/misure/:anchor",
    component: () => import("@/views/politiche/MisurePiano")
  },
  {
    name: "progetti",
    path: "/politiche/progetti",
    component: () => import("@/views/politiche/ProgettiRegione")
  },
  {
    name: "reti",
    path: "/politiche/reti",
    component: () => import("@/views/politiche/RetiCollaborazione")
  },
  {
    name: "stili",
    path: "/politiche/stili",
    component: () => import("@/views/politiche/StiliVita")
  }
];

const Router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
    return { x: 0, y: 0 };
  }
});

export default Router;
