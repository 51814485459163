<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    enable-background="new 0 0 60 60"
    xml:space="preserve"
  >
    <image
      width="60"
      height="60"
      x="0"
      y="0"
      xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
      AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABTVBMVEUAAAAKmeAIl9sKltoK
      l9kJltgIl9oHldsAmdkAn98JltoJltgJltkKl9oJldwKmNgKltgJltkJl9kJl9kIl9gJltgIltkA
      //8JltoKltkAnOMAmeYJl9kAndgJl9gAn98JltgJl9gGlNsJl9gJldkJltkAleoJmd0JltgKl9gJ
      ltkJl9gAl9wJl9kJltsKltkJltkJltgJl9kJl9kHmNgKl9kKltkJltoIl9cJltkJltgIltkJmNoA
      ougFldoJl9gJltgJl9kKltgAqtUGmdkJltgKl9kJltgFmNkIltkKltkKl9gJl9kKk9gAlNcIldgJ
      ltkJl9kHmdsJmNsJl9gJl9gJldoKltkJl9gImNkAjuMKl9kJltgKltkIldgGl9wKl9kIltoIltsE
      ltsJltkJl9gAmeYKl9kJl9kJltkJl9gIldkIl9kJltj///+kPL03AAAAZ3RSTlMAGUBogHdgRhQQ
      bdnKgh00nvb8rkLgeQGIthIKsA2/CHCQK/k82wwe+7jI+hb+OFCowLx4SJ/vjyCv2LtZCzDf9KdO
      Bij10OUvftSE3BoTQZK9I1Ty4VKgxl4JmrK0YyzwIj8/5fUU0unnbQAAAAFiS0dEbiIPURcAAAAH
      dElNRQfkCQgPAS3ePZOjAAACUUlEQVRIx+2W6VvTQBCHY1iL0SpYrKCGQj2RwxiPknqUKlgETw4F
      DzCes4ny/3+1SXc3OyHZZPniF39Pn147b2Ymc7SG8V+GccwcIscrwyf0SevkKWCqnj6jhY6MngVJ
      tbFz5dnhOqRUOz9eDh2fgAxduFiGvWRDpqqTJeAxyFFjqpCdhlw1i9jLV/Lh2tUC2AaFrl1Xsjdm
      VDBUlDBRsnBTCc+q4bl5BWstqGFl3LcKWCAK2Ekb0wB/vl2+Q2gQBCHC3fI3m/6m/eeAUvHNHQV8
      F7F9irmlNCwO+x52DPAnCJlbGscv2tu532g1Fj0Z9rDn+HHA+SgQViqrzUwWLYl+gKIGlu0Bzzp8
      +Ohx36qzJIyWJJrgqJM7JdLvPnm6LG+pdgKv5MGDwg3ePAvk8ktN10MZp1pk9fnaOjtMym8n8EiN
      fxnGPFLkZOXFS1HI+LUl3bKmSDIVdX+BDixMGlBuEUmq11QjyRjD3VcMjvOn4tJyrSerPOUQOx7i
      c8vCZknVUZu9XmdHKOXuG2HgomvaCDaW3x6u08Zm3uB6Rkpb2xh+9x4d70hsxjKfn5H2wO6Hj/jU
      6qnYaCu45tqnz+7exP6XrHM/Rn0nazZni3a0VSE2yUSNrwswp/51UIgcqkBZed++/wBwjoJ22nGR
      fv46AjvN+7pV0Wab0kB1dNMVa4Di9VRGLp/GWEQPZiuC8a4Wa7JFx31rxc1njc+EFtzBq8PXy9lH
      sF7OqT9Smm3iya57eixaT76pCxsO993T7c5IVjPC60SzN5PM9QP+Z/oL4jT3s6Zv4H0AAAAldEVY
      dGRhdGU6Y3JlYXRlADIwMjAtMDktMDhUMTU6MDE6NDUrMDM6MDAK6dOIAAAAJXRFWHRkYXRlOm1v
      ZGlmeQAyMDIwLTA5LTA4VDE1OjAxOjQ1KzAzOjAwe7RrNAAAAABJRU5ErkJggg=="
    />
  </svg>
</template>

<script>
export default {
  name: "O3Icon"
};
</script>
