<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="58px"
    height="68px"
    viewBox="0 0 58 68"
    enable-background="new 0 0 58 68"
    xml:space="preserve"
  >
    <image
      width="58"
      height="68"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAABECAMAAADnTdarAAABfWlDQ1BpY2MAACiRfZE9SMNAHMVf
      W6VFKg5mUHHIUB3EgqiIo1SxCBZKW6FVB5NLv6BJQ5Li4ii4Fhz8WKw6uDjr6uAqCIIfIE6OToou
      UuL/kkKLGA+O+/Hu3uPuHeBvVJhqdk0AqmYZqXhMzOZWxeArghAQwiDGJGbqifRiBp7j6x4+vt5F
      eZb3uT9Hr5I3GeATieeYbljEG8Qzm5bOeZ9YYCVJIT4nHjfogsSPXJddfuNcdNjPMwUjk5onFojF
      YgfLHcxKhko8TRxRVI3y/VmXFc5bnNVKjbXuyV8Yzmsraa7THEYcS0ggCREyaiijAgtRWjVSTKRo
      P+bhH3L8SXLJ5CqDkWMBVaiQHD/4H/zu1ixMTbpJ4RjQ/WLbHyNAcBdo1m37+9i2mydA4Bm40tr+
      agOY/SS93tYiR0DfNnBx3dbkPeByBxh40iVDcqQATX+hALyf0TflgP5boGfN7a21j9MHIENdLd8A
      B4fAaJGy1z3eHers7d8zrf5+AIkAcrDcqwwPAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA
      6mAAADqYAAAXcJy6UTwAAAG2UExURQAAAO8EYO8EYO8DYO8EYO8AYO8DYO8DYe8AYO8DYe8DYO8D
      YO8AYO8EYe4DYe8EYPEAYO4AXPEAYPIAYPEEYO8EYe8CYPEAYO8DYO8DYO8EYPACX+8EYfMAXe8D
      Ye8DYP8AQP8AZu4CYO4DYe4EYO4DYO8DYe8DYe8AYv8AAO8DYO4DYe8DYe4AYO8DYO8EYO4EYPMA
      Y+8BYO8DYe4DYO8AX+4EYfADYe4DYO8DYPUAYu8CYP8AYu4CYO4EYO8CYO8DYO8DYO8DYf8AVe8C
      Ye8DYO4DX+4EYO4DYe8CYP8AbfMAYe8EYO4EYfADYvACYfAAXfAAYu4CYPEAXu8DYO8DX+8CYe4C
      Ye8EYO4DYe4CYe8DYfADX+8EYO8AXO8CYPADYe8DYPAAYe4EYe4DYO4EYO4DYPEAX/ADX/AAYv8A
      gO8DYPAEX/ECYe8DX+8CYO4DYe8DYPAAYfEAXO4CYe8DYO4CYPAEX/ECYO8DYO4DYPACXu8DYO8D
      Ye4CX+8DYO8DYPEAY+8DYO8CXvQAYO8BX+8EYe8DYO8EYO8DYe4DYO8CYO8CX+4BYe8CYe4DYe4C
      YP///1PiyBAAAACGdFJOUwCAz69AEGCgILDvUDDA8JBdPTgoSD9vfcCfv4bQFpvuBAp48tdN4Pgv
      AV3j+01S2cQstP2aPshk5J0acA1q0uzp3E8Jfvn5iPV9BxWP02bWVSLVNr3hzmnCqHut+39A4Mig
      MtD1ivyP6zQC/oyjwZSmrGdIefLyiZTft4X97WuqkhLCbRi+FUhKzwAAAAFiS0dEkQ8NvpoAAAAJ
      cEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfkBxQLGi8788ePAAAbg3pUWHRSYXcgcHJvZmlsZSB0
      eXBlIGV4aWYAAGiBrZpnchy7FYX/YxVeAnJYDmKVd+Dl+zvoJkXRkp6fy0okRz096BtOuIDZ//rn
      Mf/gVy4lmphKzS1ny6/YYvOdb6p9fj1fnY333/sr9Pf/3M+vmxjf//C8FHTl82Pe7/Wd19OPN5T3
      ejd+ft2U+d6nvjdynzd+VqBP1vfrXeR7o+Cf1937s2nvG3r+8jjvXz/f2743//5zLARjJe4XvPE7
      uGCff59PCs/fzt/Cv3zPhe6+4kO+/3582Jf4Gf20fhPAz+++xc9+rCz8CId5IvtekL/F6X3dpW+v
      f9xQUfq6Iuc/P9l/XVHartmvv77E75xVz9nP0/WYDeHK70N9PMr9jgsH4Qz3bZnfhb+J78v93fhd
      bbeTrC0edRg7+KE5TzSPi2657o7b9+t0kyVGv33hq/fTh/taDcU3P4NSEPXbHV9MaGGFSiYmmQu8
      7D/X4u7nNn0eH1b55OW40jtu5njHT7/N9xf+198/3egclblztj5xoixYl1fJsgxlTv9yFQlx541p
      uvF15vliv/9SYgMZTDfMlQfsdjy3GMn9qK1w8xxsMlwa7dMvrqz3BoSIz04sxgUyYLMLyWVni/fF
      OeJYyU9n5T5EP8iASyb5xSp9DCGTnOr12bynuHutT/55GXghEYkWKaSmhU6yYkwx02+VEuomhRRT
      SjmVVFNLPYccc8o5lyyc6iWUWFIBu0otrfQaaqyp5lpqra325lsAxpJpuZVWW2u986E9du7Vub7z
      wvAjjDjSyKOMOtrok/KZcaaZZ5l1ttmXX2EBAWblVVZdbfXtNqW0404777Lrbrsfau2EE086+ZRT
      Tzv9M2vubdufsvY9c3/Omnuz5m+idF35kTVeLuXjFk5wkpQzMuajI+NFGaCgvXJmq4vRK3PKmW0+
      mBCSZ5VJyVlOGSODcTufjvvM3Y/M/TZvhuj+3bz5X2XOKHX/j8wZpe5L5v4zb7/I2oXmeTP2dCEx
      BSED7ccFu3Zfu3jpz18Hy13tnFzTmSmPnXnecUw4hC/USc562S6ttFsDYs+eZ5za99mr7JznPHOw
      1BAaNJT4z5VYQs8NsOo2G9By+dKGi3WKykLeIdc9Zhmt7+E7RLXsGYQDXOx+10gVpB5KS/s4MjDI
      yB4mr71dTPxJ7ZCrsofruZzcV6eAduENI0duNfLee0aYrZ7UT+j9pNR3CpPLDzHyO/HEKxxilVOv
      ze9e5ijO57RsY711xD13GKet1ePou+UTFznJN241bFfNX8X2v/36eSNCCqRrVUBSK2eP0fTTsmSm
      W1r6zOAKC3W7J7tan62O0hzl14iqoWrAAaojk7GZ80h1z7IBGbvpqzQokuTOgPNPWrp1svsQ1LVr
      iPNkILLF4wwfOwcrWCNSaY1PJwZ+zLi62gWWy+kMT+opgUXId4Le2rwxz7016ruWGQ09C0/ZuOGM
      SCwndxohFbr6LKUztXbarOmmlvX85qv5qwu+fXV+jTQntVPsCJOq8DHN7aBsu6E0nnj0swJrpINP
      tnXstQoxb27uG3TaIoVKYNwiajOx3EWQTyzWtZ6NdNQaPe7deI2sRLUwhdZG4yW+D3bt+H48Va+P
      tzGjJPh0YRAV7voxB+GyRoFj9bknkqiOSiF7kdylGjuNggqh8PPxsR3evUsgI4AR2oNA92FHN6zR
      x8Fz08PozbnBoUlTpuIGFURj8lEjbfJKXz2VVRe9MZsqrY2cW119mMq9F+XeEJZ5ZIJyklWNgsg7
      /2WyPr+aEsG+7cKYQAbw4cl8LnzmSm0VHwlPAAez57KYlxtnUONPGOrhbWuNM8ka+A62sIy+Uodp
      9i5r5DRmaPyPS2Bq4fF5+DZ253EWcex1+T3D2WVm7rPP8kb3rqmE01upaYO1JdnGneALMuyB4A4s
      b0liwUPo+1a/B9DpkFKo2JxZEYqVNwLk/ZSFTph8Q4ojmZjNr558TxQOVFSqHvOs3McqPWX+JODb
      CXPyMC/kOhB3gTnoGcLNEhtRiuFQByAl5TgGLelaKFmd5mhvcK/DIzyCaymYaVeMPNCxFCOslOmw
      kry/ieu0df2KPIOAhVsEQM4AECvIs5G91ZCJWnrYlAIcnPaaYDjIvuruRDw9COUojPdNkeLqLYzl
      0vBAS56BN2QTYnaR+OwUqcjJYinTkxt0nVuIq3C1gLr1kglIBAiIIJl2qVKnlOHM4MMyfeTaLCEB
      gVezYW/CMYZzrCisPCYLCw19J+qDRi28PmPalkU3bn3SBstrMhdywPf8UIkM2OfX3DPJJybQNQA4
      7mMOKPqWIPenAmn/foRHQELkWtB2juBdV+OUvXKCXLiIxy9rhd07q3M7Fawr39uYoHYF5ySQlUQb
      ICcOiX2uQpUULvCRTsCL8v0ZsRyqFXwJxANMoYgHEoU/pH5lyBapYxMaclGjUgoIlxY2pUWHhJV8
      yzfdyov7qADxYhJVb0q+3EdNC0Y4MscAELiTJr1/uvV6M13XiegKPK3zMLmcspsNbHNtD+QO0W/q
      2FgALOrJHqOqTCSfZp+qDW43UHEX5tpx4wY5rgOvD1gGtAIET10AwDqULzpiwl/T+KOuL4faGq3x
      REjNjtxA1fk5qSrQhqbL5HmousCZReV61BRFR1d210gkpoYP3pEc7u5Ftgc0VqEEIBCQ3KHY3eWz
      coSg6OGN4mqRdxBiZAIVlulGb7ieujvYPB6O2qTf6Fg3g5+OmKDj5qwHfLCbR83AKQ86/JVPtlJS
      CCfsRzaIqDVU9TKAx/WB4KQfcsQjFtaRdmou4xIVRGJ+JoCsthf8LXysJD31j+/f4uGF9IKVUFlo
      0E5Ywa0C4m8Q72IgalIQiMihESlA/SRwIvnlQPmGliMDkQpy1B4wjTgYsIicgkcBIkSFz8qhBT8k
      4Zp3FFsaknAWtAh+YCGaqB/TK/2IOwFwAlkHhaan5SNlAcRfRugHQUlpSE/oNoOYANoUWhrwGtpL
      xUUaPc1HauHbURYARhUDCUlVX1eNlkqEqYpaBEl9IvA7wQofBq5gG6hnzuy4P8QiazAlGHuKg3ef
      yn9iGzvgS+/ZUwTRiAToScV7fhSv+axef6uXUCZq7IaSGoJnqXpQw3d0UHM0Xgcn6tk7gxq+r0d5
      7m54z/sOa9/3tPDxQe+noNLfLkkITwgQKHMsi94IQDgieFXksZ1AX76Xx4lOGUVS+6C9gsJE2YBG
      G26blFfqPiDlMjipoQj6B6eCwOhmSbshT6rrAZOF4UEFeeTT4CbpoCMXDpm/tgCl8IuqelmwDg49
      yuGSRYEgIUduu2QH9iWa7ezl9n5/IiD3sVfjLiVmHjw2iK120moRtjgoKSQDnCUeBinagm9L/iy3
      Xp9HDfFq0Jn3aTBHxvKJEnhS+rEnzFMqsjAky/hrVc5zLTIvdB5ho4Q9qg/URQOSJFpG6mjsE1Dk
      RzpwDHhuLdiGReH7o+i1yKc4IkpxcoVf+UppbNQ6yAbYedBj+NOoXtvlqspDpzwSJFHHZjzycwm3
      V82P8ISe7meS60Df6F605JLmj3MF+W4aabBcDOZqQGwxGzO1b0SQQahVOnMiFeFNaivLoWNsWUyC
      ZoRZXRYy0R50Cp01o3Mu24Cqrbv4sJAQSnNEs1JijtTpabMIHVRfp0SKuDWy7utEN0mrDhg8eXqM
      2AMjEcheB8/cgYEkuwVQZm8h/IkXA4avV4BZ4RFAnyumk2xYyQUoHe6JULJBWtCXICK0g3/gaTxw
      jpFkeSwGgetAK/Q8KgrHiLaWuiKuSwiKK6XJqaRpaAUnD93BYCphNDgCRkTotp1DTThqCpn6AcsQ
      3EiGUIQ7eDGC0tJxuJnYm4mqbGK4cAxt8xjpU1YkEkRPNGz/IOIbIbIwZzSSiACpSkNUiQhMWTQS
      OrF4ukUrPi7iuCmsjf2FfnDTHWVWeLxET4NQiEv0LWrZFoBkrQ1YUezDpEeZUITkBewAYHJqBd1G
      ZNfx+uQT1RhVTI7UvJdSsOfFIi4mW+ZeTXnNcq/GJOEkNN6h0aJXofe5JuHDkMhl0EbUp5XLWMgM
      IH6rFaAjC4Q0nqQkaTDUEDCJlrrGo3r0UBwRElDyUMd8JPoCswhUaIzgrIc6wAKDvB1OI1PcMRIJ
      8RUCXY0rKwhGFc8QqCMEqFdCT9EF2T6sE5JzY+B43HN9f4VkU37Ux5eWWH1xww9MwdWunxHlogSI
      etshTmMFMhlZ0QBrRB7OkC4s4l58R4dx87myEgGVIiWPFEdS90p8qKSAtQhkSWMfahS1FjyGLmtY
      VKinUlYjDAEr4hH21CNdRM1e2EBHx/48Qb9PcOmIh2DRo/Q1qciCAnHImoyZkrhI6GF0jsU0BfGA
      o3/AuXomEBZweYv/hbMjWVPdsphNl1FX47FypJpO+nR10smQRwwYnBPQMR3Z3pdrUbMoD8tbU7Aq
      aEhWm+x1E1zlwDzgYEPRiIAsngcrSrTq7OqmDatARSQlYTIH7u14I/uCBoJK8bLcPWlwjxQgy8hS
      zHdBnbSIbkBoUAZZ0gvvjXBRfCi4w0O5borGAcBF6DHi1Yo8FJSEwN9bz7uhsQQaTMzwouiAwFyv
      eDny9HKXAaTzRuYcXADesSTARtZEFOUkSOVKsCZndCh+DmVJbWqgwj1KxBM6UrnuPwM18ngPQAkD
      8d2OaDyg+QWNk5HVlQfH6qZZqXuWDW4i5EmkL9o7QurCD5RN1mBIxbyX5t1ErxOdKTeLpLEQE3xX
      AqhiNc4D+NLKeW3neBxTYV2HJJpyEPAtzVkhbyKIHwMJewK164GfIAXa1+MgRsAdoSoLSuRsmgCx
      a1YG65ePpQTH2qesDWQOmk6NJVYsXBVBHbcxeEhci0tYdUgt0sWYJtw5Qshgls6jjh7JKQeVU8qF
      i0DmQ67BB3n/qBkk4l8txbNjKAnP+hAA5qHf8TsJoMEerD5APRoCYNrgLJyKA8Znn5dMIVXzACbr
      ujLYwktYWj1+FIA3Ytsc0DqgevobMw8kjMDtS8klovsDS0xtmaQJn+Uucz6uLBb3+9GK3CMcdL8m
      DZTgcc1zyrojjUc64NQJzNaGTYNW9MBZMhWv4zVNOBX+pJn4B5GDGRl8L4O4JTnMq8pm1mQCnU+E
      ngptO/xuZTiW/UiaZiEx7RSdeyNwGgfvNG36jwu+/n+PQUWJzIQEKRQUJs3rwRWCHdvHgIJvKqJm
      V0gIjE8a3P36/7qGQ1Qw1pgkJ3KWvDlzvU8AAD+jLFriT18XIohyqJhwvAh4RW3hsgXWYnPaydtS
      8Bq5PvMSh8yWQpKi8VLaGEzIZ7fMZahumrwCPWgWrcqgwSlltPFCbtDy9LDerT0VXIwGtShKeqZq
      iuPRxJ0mpoNg7S0nPS8NbBxkQITNa5uaxmcNPT/AL/QZUkNAVB5D1LptaJap8QLOBx0WrQgTgVEX
      LVK5G07+uKIS4FMPUHNNDc+G9MTihpkwAHPg7CBcgEnTd0n/fLQh+kyajD11/zKmbU9JUHQ2yI/q
      ox9j7ZWcDHFdhGl4CBATAzY1rLMTO41YwceEWgHEiKoIsbp48Vm0DbgmeUCMhjQSLTa0i2NJF/yD
      e/c7GoeisQ4elEgqsGlSXP3xZVqJKnj0QsqQPKlocYghIKo0yyOyID2lRRuYGCFpKyLxtEoPY2WH
      CpfBfPwUXslWmCWPFeVGj7asWYul0DXwcmPK3BsW3qoUDjyk9uqtRUFaWNBxzMQPbuzwzMLoK9mr
      7qxdnX613afbND/ZTSuNnTSpAlkwVsQc0Pr1aAVDqBLF9cJ+WAjbKTGf6oxT+4EtWY1XV0YxX7fZ
      18NN2ZNCXEjFqjsKpta4UPTZ0xhh2GYoV1cCTzVQAIh90oIuikELyf6OPUJBm9qMHCFqkJLmTTwu
      q9UsHVaIpN5gCZOUMpYLczUPEgusX/gMHuXgoSp6Sd4SDZ4OwIHrPfTYA82n7fVQhykauCkTaDUr
      1zqxHgRSwyXQCJ0d5fdBMri/IgciT4gBjgNIXiheno0VBRMA2IJ9utAPdQXa31HATbrBDfyt+HxR
      lg2FFcMW6lJvQaMkpH5IKK9gBw7S2eEokw1OAMakz8t2oAmkVhARyC+Lb6ICsFgraE+LGCQlgTrI
      iHTc/jKKopXStiRdc63eIDmEbvTQkQ4b3LqUGRcXicXaRIZmC55MHA8CRQNO88QHxQFkrbvVuuAI
      SFRtILaQQYHsS4W+EcWyCKhNMhm6zz3kqznmMchOegcpNnRRhpeHk2kgCiegozM0NCYdE7uG5vRE
      lzRCdaK114PRBzVvbrFTMo2u/JDm6VOaV/tIc92yYuBXwM81T/VTbtwna4zgIOVggC4XtRssQZtu
      KOTMsgZQ9AUanbxomxEbu7QdiO9GlAEy2rB8XFDUQJMqtWjAqYBQXDGD6xQy5tEHsT5ccBDYcUfU
      czwDHQ5YKFWgLf5qx5VGS9toUtotaaZBO5YJdJhCMuzZXBoLX8dME9btkxwzrQWq4F41T14efUxw
      K3adunUUnUaiTcNFKVbt1CZ8gG61tF8hk5ZxIZIpSxZNdSk+1Bmq4SgGg1zo2iAouKhEny008UI0
      EcfQHtDmBrb4K9YTXvaKda9naFSQ0B2c20ZOAU1QiZv8VhyEHrJRBXvqBm/3OJqglkfpA9F6VDT+
      GIB9z6AU8JoMSdM2h/z6frxPPh/eR6qp4g82Xnc7ze6oAiAjbo1hB6TqIRkuoK8MbAiG0m0kW1MT
      hCkBoH20n0k9LZbn44nAQKVFkoadAIya6W7F4w7LpCKNmIUyGpqQrX1tAe2mHzDQuCoo8R4Ciur5
      ITpB06Ib7sZZ29p7o8L8MsRtiaHz3eSBpqbXiSOxlANirpVHmgFmCKcdXbhcXB4u5i4eJKAyppna
      +qjZEVd4qEcyDQwCEsCiT8GuSZChVFYAyM7KPfGepQNF3EsTc9iEnjaPPltoeUmoSsgpJjxlai0H
      7fRo65aLNMlSB7r7KdC0V9Kp9+gdxZ6Mc7JAGwxHQOGSbEUz6CiGBkjRysKImnU2CjELjRaFfAYZ
      9wBWDZy9aNQ0G3XyA3UFIzatnKuB9kLxRzmoBCtuuoBllJCXXaqg+FRQUwU9BWRUQdthv+RMPAp0
      S/BiuLemn9LrwJAnyRZJ4jT+gWJoREE9xOiWpmM0uMFAEeNjgSdqt3uQjyB5wuTJcmB1yDPtCjgM
      DA7mwJBQmCZxeKN4eUa7PeYGH+WMQHWObtYO5XMawfM99Q+zcvdOp22WO/d09833EJsfkui3D8xt
      ifa1Ja4mDJJPYt2IJnpZ16MRLutG7RZO4SZqdQ+sYFgw7VJvYYa5H7Q/cUYY7ULVrKhTFJ5gBuwm
      2fQes8zCadO+gI90oiA4Z2Df3G1dv/gkyAzdgUwD1mTK4UWWOa/ewViq+0OnzWpRg0WYBvhFEmVo
      5E5r7nYo/IsEJxvZa/MJzNlua57C2xGcHkZE0B84GCjjGQL+cqJJtvQzWjEbbtbA79Z6+Knkv1R8
      fUcrvxoOsW6yOhBaQMmAttuzYZKvBqjyYCGkd87LJXfOe30Jua5J5yDy0cEa3A2CG3I1WTsqOhFo
      kU14UHSfNhu2ts200YODAB2QL+VKQawpPP3CQ33hAZiIBrvHynwXW/C+3JH9Sdqa9HNPTaD+NGeR
      rIqoYJ31S/K3AHZPl5Rc1W4AgmhQgtNrWxt9CiNGuFMjYuRvOZe0AMrxbgk187mVoQJVjeMdUVLa
      9TkzZIf31PmRFpRabeVoS/B6Pe34l88df6NDLtrx1/DlREtp6hCEDsig6e9wKNs73jrBxzsKzfsO
      y4hNq6QroVNOSAaWo84c5kFKAhe1tJNOe6C2yp3SN2TWsnF7zYWzeyyQpTPOvTt8yusBOkJg6fBT
      kH5A+xS0P+9P8qsQ+NFmPlL20bcbw6I+q08P7QH4tybaMcBG1OaMyJ37lXs/Fg+DaNaqozqIjAyf
      wGgnahqy4Fa9I6XFQ7tnKGeIcv4yIEgJny1KGRm5q90m4OJIdafBGlAOaEkqEVyYn8e4NGY3WjEO
      Yn6c4joza0ceUwD+ow8zBBRosZ5AXoAsar+jqOi1F9ORVAhQ2svUGg4fAS3qwA0WYiSLliAk+Cqr
      g14a7eAWu7YYlzQm+kdvBgRgbNCVpuveQOLgPh+BkRiQr0ZERUew8L6yuDqp0zWnANzoD3LVCDla
      2vqsBOOzUC21mHXnewjUqStIcdQxoLYo8EXANgEDsOd7MgKhFuP341maJkBHmDFUBC4Xc3Irgg4G
      qiCnNR1wNrUlMm+hLu3fJ52Vaxo9w3Akm4hhWjIt4uR4YBlqZ2k7eNKeTWMWL7WXJbS0BeB1AOLQ
      6HAgeg2lCVTli1txOx0besYqUtfa7NRm/hIFtsPn+pG7lfGSpfUl3NATI8BGbsFrrwUlMIGRRNth
      QfqRRM/akuq3ITFHm5XoAAJ/GtgGKSKMaVryrnBlxSYKf1cKzvDMK90zCJpqa1etwA7l4+TQ/NWh
      i29fEXK+GTQFdejQ3oMsypZGbX/C92ADXIo3alUnvKAtr0lv0HE1J8MBG/GbpoP/t+HlIVejg1nY
      DdhUq+JqfMem1VHheAX0Oj5y6fhHRAnaXKLnH0li3gcjLmM1janPNIbKRuchc0VaODLlJvW60Z27
      U4GICGQmbvogaLkTEG8xlVZ3MwiQKR2qc3mX9II24Mred6zokV0oCZ060ygfqS+mgIrCBaKE4wUs
      SFg35JkAYE2e0yVZUNoj3jGVRQXUcnlc9bl1oqhq40TDfw1TwG1KEpUXUjRoH22/9qzg9veMJX5m
      /s1zjOb3F4xlwz1s5HXi9CHOq1SkGqbOOV5DfUMwKzdaGo2WZ/TkugpNFt4ObctGTTpgXR060koR
      SjpAunSScTwzScilYhaN9LvG5OgA4tlsn2h/BJ3FcExt7dulo3FEu++0UX0oeoQ+LNyG9iDgX8Rv
      bAYt6GRaNM68p1mguSCl3VVWVQNCqIsaUt07OhdEe+sevGzrYwpm/qvR4x+/Ppv+pk/NSzXiwIW3
      jyZY9TZB1t5hlO4N8pIYoBg82kcHqqElbdVQkhfmTaECDzpKDhd+0WAMKI9Qxi1IdMY92aUdD5WM
      X1uHQnWiqAPOHrUlLAchYR4fdaaP5tPFwBg6m+tDg2Lt8EgGWD6BlgSKHmmne7rinjIYEih00Wrc
      aAynrCYgV+ecsHiAP4Q8NSMQS2t6bHUcRtaDC8vTVZKS44eUNK+W/FlI6iAqwv9IS56q3NHp0KuG
      Yck7qD/3kw/de9soalY7x+0je8+XwMhL54AUAi8E1L0FwXEmNBOr1jCedb6oVn7Mzv/uKdY/HIdN
      jQ9kmZoY6zDCnd7RQ9LFyNMgFkpW+2YiC0tIw90bTIXe83jYBm9b8MiSiAwlwaa3yEA6ZBKPQAo+
      ADv+9ZFP824HHJ0XNv8GQTl/I4Hy8jwAAAOMSURBVEjH7Zf5VxMxEMe37W7psduqhSqlKooseKN4
      UrxFURHF+8L7xvvG+5w0LVL+ZGeS0nYPysv+4ns+8/pLk3wmk8nMN1lNm6uFwgARXVNvRhhYkQFE
      1dEWgCJwAFBeN4ZQSaBxVVRHCN3FllBFkxIlWhU1a6ilHCZrFlUPsZmSqHKUiLWKiKYDkNgWACwM
      RlKoFgVEMwCt89tva/PpxYxKzgMmE5Q0WU//YoAlRlMyXZIZ1+7qD9HhhL3zjWwul8uSTZ0B54J1
      eQeMlctel7EaqYVNTYuX0DinhZ0rGNjHy7zDjeYFyckfyTHGnYm+dBmnXlie7XQGhkDGeREzlEyQ
      x2igPmHFyi7qmSJnVnU7IiBXwVJu0WxpBif19FaHV69ZC7AuvT4dj27YiKN9m+roZrE3XBZLKgTS
      eTTVv2VrBMLZtm3438rIqZ3bd+DAzoFCGCLm4K7dcqOEhzQjX2X37CUDTIR73/76OgcOovND6GXp
      UJfgShKIYdJINm8OHu7HASSHjxx1hObYyBBAWZz+8dEOCYKsqWjciouKPkGeMH7SkwZj6AsHduo0
      BSoFwl1ns7C2+W/wyb44+jnNCvLEo6GoZ4pOa1b8yttModG81qQl0ZkzvikfOwvntOYN4Lz/wAW4
      +B/96+iludDLTUEdxbES8cuJ6BXgiSbaapCsznhSm2wCmyqLQptrUeTw53OlhqBMBU75r7fncu21
      F4Y5PjKO4qhd5dOITl/zoteFWLMbKNuyXEekmxHxJ2LcvAWsVAG43e0CB+/chQrHEcjdq1a63FVV
      Ydj9fiE7ZL7vQQP4cJSkp8QrfAKEwNYERuqaQHoePc6nnjxFIZt4NjBLtpLp5y9QOe2Xr15XJRsb
      7tueFUR4MyYnCyEbfvtuEuUx856kuHYRfiAtRQ+EOBkCZE4NF0KGjjD+EeDT586GSFcvC7FZslK9
      ARo2GPuCo1Mkzl+/OY5P6j0Tt5uNj0EZHIf26GgLTxl6NUezoBqqvPCB1zfesCw+Emc4s13nFEvJ
      +KbMhsNxJ5FNyoqXkjtZ42KuTElDJkjalfWmjW599ykFQ9frvWYhFDW9c34A/NSCtVaATEAU8/VX
      1ggAmmHxvpw0lUn6bhCv2kRMFU3XnuHK79raW1r9CS/qCcTlr7pbW6BFQlWDbAmUHLYVSVFeEi2o
      ohRiensE+nBIpghVX1M0Uw/yEfovtj/oxsFAQrr16wAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0w
      Ny0yMFQxMToyNjo0NyswMzowMNQtE5EAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDctMjBUMTE6
      MjY6NDcrMDM6MDClcKstAAAAGnRFWHRleGlmOkJpdHNQZXJTYW1wbGUAOCwgOCwgOBLtPicAAAAh
      dEVYdGV4aWY6RGF0ZVRpbWUAMjAyMDowNzoyMCAxMDoyNjoyOBf4+xMAAAATdEVYdGV4aWY6SW1h
      Z2VMZW5ndGgANjjQR9psAAAAEnRFWHRleGlmOkltYWdlV2lkdGgANThRjv/IAAAAGnRFWHRleGlm
      OlNvZnR3YXJlAEdJTVAgMi4xMC4xMC091QEAAAAbdEVYdGljYzpjb3B5cmlnaHQAUHVibGljIERv
      bWFpbraRMVsAAAAidEVYdGljYzpkZXNjcmlwdGlvbgBHSU1QIGJ1aWx0LWluIHNSR0JMZ0ETAAAA
      FXRFWHRpY2M6bWFudWZhY3R1cmVyAEdJTVBMnpDKAAAADnRFWHRpY2M6bW9kZWwAc1JHQltgSUMA
      AAAASUVORK5CYII="
    />
  </svg>
</template>

<script>
export default {
  name: "COVIcon"
};
</script>
