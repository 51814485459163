<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    enable-background="new 0 0 40 40"
    xml:space="preserve"
  >
    <image
      width="40"
      height="40"
      x="0"
      y="0"
      xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
      AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAflBMVEUAAAAGMEIHMEIGMEIF
      MEIAMEAHMEMGMEIHMEIGMEMFMEIGMEMGMEMFMEIGMEMGMEIGMEIHMEMGMEMHMEIFMEAAMEAEMEQG
      MEMHMEIAMzMHL0MELkMEL0MGL0MGMEIFL0IGL0IELUIALkAGMEMGMEIGMEIALkYEMUEHMEP///8d
      dbDbAAAAKHRSTlMAf9/vjxBvgMCwkKCvYM/Qn7/w4DAgQFBwCrhIQc57jKI+HHr2VQs/L3fkXgAA
      AAFiS0dEKcq3hSQAAAAHdElNRQfkBwoMHih0cqEBAAABJ0lEQVQ4y9WUa3ODIBBFV5G4aBNEgzVt
      +n7d//8Li6KGTDKGdPqhveOHM+4RFh2XaEiSikx6lJlIEzrwioLkYIXCc4GSkXu+6XkdiBtNpIRn
      URLpjef+Yc2BCO5XHdmtxiGPoqyMMRDGpDBDkBojQnY14w6gwMyoeQ6aU9YoyV1ETdDHdKgj5nRo
      w500207FMmAWK+sBXpQK9VSUDD2x1eAj0ZXtYUMrT/ggXsiVou1fWUvqlipzNnIUW2hWloqWOj6T
      Bu0stkvb5n9R3N3dR4n7BzzGiE94jhP3L694i+vxP4i794/IL/P525/wStH9VfarT7UsdnD3xyyK
      ck2yy/vwT3qskoWYWdziQuw0AGy7GOsHQEER4dpNNhMRN0Wtqi+1CNSl/AaWoD1rIZNi0gAAACV0
      RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wNy0xMFQxMjozMDo0MCswMzowMDNKtdIAAAAldEVYdGRhdGU6
      bW9kaWZ5ADIwMjAtMDctMTBUMTI6MzA6NDArMDM6MDBCFw1uAAAAAElFTkSuQmCC"
    />
  </svg>
</template>

<script>
export default {
  name: "CalendarDaily"
};
</script>
