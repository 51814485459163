<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="97px"
    height="98px"
    viewBox="0 0 97 98"
    enable-background="new 0 0 97 98"
    xml:space="preserve"
  >
    <image
      width="97"
      height="98"
      x="0"
      y="0"
      xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGEAAABiCAMAAAB3TE0/AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
      AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAACbVBMVEUAAAAIl9oJltkJl9gJ
      l9gJltoIl9cKltkKl9kAn98JltgKltkKltkKl9kKltkJltgFldoJl9kIl9sJltgKl9kIltkIltsJ
      l9gKl9kGl9kAqv8Jl9kKltoKl9gJltgHmNgHmNkJltgKl9kIldkIl9gJltkAqtUKl9kAougJltoA
      mf8JmNgIltoKltkJltkJldwAndgJl9kHl9sJl9kAluEJltkJltkJltkKltkJltgKltgJl9kAgP8J
      mNsKltgJldgJltgJl9gJl9gAmdkKltgKmeAJltkJl9gJmNoHltoJltgKltgHltgIl9gHmdsIltoJ
      l9oJl9kKltsKltwJldwKl9gKltgGmNsKltgJl9gHldsIm9gJltgImNkJltoJl9kJl9gIltkJl9kJ
      md0JltkKl9gKl9oKl9gIltgGmdkKltkJm9sJltkJl9gJl9kKldkJltoIltkKmNgJl9kHldwHmNkK
      l9gHmNoKl9kJl9kJltkJltgAld8JltsKl9gKl9oKl9oFl9sAmNgJltkAjuMGldoJl9oJltgIl9kI
      l9kJl9kJl9kJltkJl9kJltgKk9gIltcJltkIltgJltoIldgIltoHmN0A//8Kl9gKl9kAgP8Amd0K
      l9kJltgKl9gAleoJl9kGltoAl9wIl9gIl9gGmdcJltkHldcKltkJl9kGltgImNoJl9kIl9gKl9kJ
      ltgIltoIltkJltgJl9gKltkJltkJl9gJltgJl9gAmeYJltgIltkJl9oJltgJl9kIltgIl9kJl9kI
      l9sJltkAnN4Jl9kIltkJltgKl9kJl9kJl9gJltkJltgKltj///+lYLXXAAAAyHRSTlMAYK/fv48g
      UJ8QcM/vgKDgMLBAwPBfP5DQWAP8aLjYSJTcf+WY2QbrC4gFb4jq/ToNWEfcEajO29GxTv4COYP4
      9KT6FO4ZyLx0dcW3J/IjIlO9hjMdhOcq7PVGIeXZddf+1HMe3eaCzXwo1hz48pU1z8JPoiRKnUXo
      943nGDjSZ0wxNO8JKYnZ3F14k63j+xqnx7nIY0QlAe3pBA/V7tMMyVoWYn0t4pO0Ni4+5EKaqoPb
      9uHxwzuL2gqKPW7R55dlG2L8H6n4+hSM014AAAABYktHRM702fL/AAAAB3RJTUUH5AcNDAsODB36
      UQAABy9JREFUaN61WueDE0UUT+6O5LJckk05Eu8QNJ5wx3kUAUHuUBBBBAsoKlYEBUQFOzaKDXtX
      QLBj7733vpMJ/5NTdmbezO5m91LmQ7J58/b95tV5s5tYLOKId3Q6TteERFT+8Y4kEc9Hd6otAJaj
      xsR2QMQdp70QqR4NwUm3HCGjAzg9LVciayA48RYD2CaAk2sxQl5KRlX+XWgPAkbIwe1FkB8tt1Ks
      yMRWEbUTu7RajZBRSnCElhenhEJgVsq0GkCUJWGlrJ5wdu+kFmRgBlipaMOZJKsoHc1jxIscoYoK
      mrCSKCTNuyZllZmXj9GoKhm7GpJqT9Is3NdPJOU1joKqJPnxyaaDb2twS6MVcLLG01RJ97HwseRn
      CfIkmqmGSR8LlzyCmtGhrO5NChpNiykGF0IY6VwRR8pvdVPJj/4+yJanIYxoGJfHq0IeIMhanfDW
      pOO4Ak5x3PkgdEAYK+eycJ2q8U3m+1KhgYTjezPZdBCw8BRP5abuOj5pyk/0Viq9YaBxvq2ROnGC
      IuYIbQBy2X59Qb7L7atCkjAjdoMTp0laydxBKWH6oPdGd4R0oGmxHQxJEg3XLGCZMd3MQaO5gtXK
      7q0MG3U+FU+fNELYZs4SFBausxXHHCrF9qwLDAGfqritdYfpnpMpde484UFDYsGTCGYD6ga3DcgV
      A2KAEue7P1i4nrLAGVl4anrRYCwx6oktywDgSiQ1UreOMDiXEufQy7HFp50umwHHWbJ0Kfk8Y5nG
      XjAROg0NfErYrJmEdubyWN+cFVw6FoysA5yvc7PGp4qREucHa/hiiNLOWslSsKoh0I+zdWa6z2Kl
      J0PImwBmszJtlSPlqpbJEfv26nMg87m8tcUQoeRB6DGcfd4Sh9UPId2FQO733PNleqxZK8BrQlgW
      Gqkm7jGz/QJ2E3atJBaIRf80ciHjWnfRxdJ+yhElsE3hKvEdAzEQLlkv+ZkNkCnoUmKoy3L9koyA
      GxIQwV0ZqukIl1/hNpSaE7Rx5VUbxOXVCMMZmg7yCKWWtnHTNQpg+bXunLQKNuSD4Ny8ZV0OTpVp
      HZIUXAUL3HrdNhisRDyf9eiBwZrXrplB6gYofTlW6MBZGUF25/rTaAW64UbhJKkoQMBq+dt3iLBN
      umbJuhtHqqgvCbppwdBNmtJMJFiFukI3zwaus9NkqAqpH/gd55Zbb+uBIrFpd6C00mT09ljw0A/k
      RWK7GXfsXA/kaHppGrlq06+6xxYIUXaV67tz4C5FrVX9QRCSE3fPqweRlCFbAtvc2Kx77jXW61EC
      UEOavmSmkC0ULHOLK0kv4ABDqVGKNTJo0cK14GSGY9x9JRtZ6WscijBzXgMAY/2hcsHY1QDCtjCh
      CCS1s03el6p0d1QiHU/tMARM6x5xFKKJuVvclmcZ2+MbXIluMtkpO5pQHarwx/Y9q3du2Xvf/Q88
      6BJsnzW75UI8K9zlelkru4YOfrZz48J7AlYNG5l7aN/UvQ/jKsJ1RNWzHh0eBF49iPMwemS9sawA
      T4tR9UXwmCnrIvgtKkwJXHv0scefeHLFU0/X0yHIuGT7CUWQ5+CxZ54VpSYThjC6+bnnCW81og/k
      AS/lVoKiNyPKYskYvfDi/gNk0z34kpJQM4ufXkkOHVYxz3owsQ/AAXY7UWSHoDx9j8B6wdXOd3nL
      8j/ElQW77L9fFgHMpMNVM+E1CdG/L0qRiKUKBoA8NXFJ0CVcIanFQCQAql6mUCjBfYgr4QJoVuIK
      uaAjy6IieMci2WWbWcdNxCcnNw5AnI39AWTHT7R4ZawZhMFXmZyq4x3cRAi/9nozAKk3eI6olR89
      elTow5MXv9kMwJE9Wsg4RuvKDdX/VuMA6xZQCUs2OgEIDuLnqbfHLTnBa8o779Lbe3YPrgpCGH3v
      ffa982AsNWm4u3s4yiNqewJ9fNPZMRz7gCXDoQ8J8aMNvggffxI7/Cm7+uxzQauEAagHB198ST+/
      OsDIhzd97UH45ls29Z2j+2qiXU9+qkvZgwXK9/LE9cP+H7cChIVDi8XMylEVWGzUg1CHX14VFh7R
      pmf/9PMvhPrrb7//Acl/1lRgMS2imIiz//W3h2UHIf9j0ErcbiprAtvkhOw2kGvbjigItvCCirGg
      54iWpgD7ioJAty5aeEF1DzpNZHQN6A35CAhpzg33wKDH4GURRUjWNSsCQgEqzkfQS0HJQHR295tS
      ZARtBCGot7hyB05HQPA+QQoMppyXNYof4t7bgl4wp72sZiFL/kuI/w1rNO9ztsCs9h5JzPNfxq0a
      ep54HBH8zqYUshZL1iUtxvSHI74NpWQ13tibfi5KBP3PAvoz27qnd1tbjRlztqNqqx4CFrivWP/t
      cgLY1MNJl4pqNXaGt4LuC3+hYpVdb9meqXw9S+TTuWw2l472cjFvJW0/OnzF1ab/6KhYa/l/BYQS
      5fB4bBaCF5Zc2wDIsONpyx4H//8p7GxRU5n78wAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wNy0x
      M1QxMjoxMToxNCswMzowMBGD+agAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDctMTNUMTI6MTE6
      MTQrMDM6MDBg3kEUAAAAAElFTkSuQmCC"
    />
  </svg>
</template>

<script>
export default {
  name: "NOIconBlue"
};
</script>
